import constants from "./account.constants";
import signalRConstants from "../signalR/signalR.constants";
import theService from "../../../services/admin/account.service";
import alertActions from "../alerts/alerts.actions";

function getAll() {
  function request() {
    return { type: constants.GET_ALL_REQUEST };
  }
  function success(payload) {
    return { type: constants.GET_ALL_SUCCESS, payload };
  }
  function failure(error) {
    return { type: constants.GET_ALL_FAILURE, error };
  }
  return (dispatch) => {
    dispatch(request());
    // call the service
    theService.getAll().then(
      (resp) => {
        if (resp.success) {
          dispatch(success(resp.data));
        }
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };
}

function getAllSoundOutputs(brandId, licenseId) {
  function request() {
    return { type: constants.GET_ALL_SOUND_OUTPUTS_REQUEST };
  }
  function success(payload) {
    return { type: constants.GET_ALL_SOUND_OUTPUTS_SUCCESS, payload };
  }
  function failure(error) {
    return { type: constants.GET_ALL_SOUND_OUTPUTS_FAILURE, error };
  }
  return (dispatch) => {
    dispatch(request());
    // call the service
    theService.getAllSoundOutputs(brandId, licenseId).then(
      (resp) => {
        if (resp.success) {
          dispatch(success(resp.data));
        }
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };
}

function getById(id) {
  function request(id) {
    return { type: constants.GET_BY_ID_REQUEST, id };
  }
  function success(payload) {
    return { type: constants.GET_BY_ID_SUCCESS, payload };
  }
  function failure(error) {
    return { type: constants.GET_BY_ID_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request(id));
    theService.getById(id).then(
      (resp) => {
        if (resp.success) {
          dispatch(success(resp.data));
        }
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };
}

function getSoundOutputById(id) {
  function request() {
    return { type: constants.GET_SOUND_OUTPUT_BY_ID_REQUEST };
  }
  function success(payload) {
    return { type: constants.GET_SOUND_OUTPUT_BY_ID_SUCCESS, payload };
  }
  function failure(error) {
    return { type: constants.GET_SOUND_OUTPUT_BY_ID_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    theService.getSoundOutputById(id).then(
      (resp) => {
        if (resp.success) {
          dispatch(success(resp.data));
        }
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };
}

function getSoundOutputMediaPlan(id) {
  function request() {
    return { type: constants.GET_SOUND_OUTPUT_MEDIA_PLAN_REQUEST };
  }
  function success(payload) {
    return { type: constants.GET_SOUND_OUTPUT_MEDIA_PLAN_SUCCESS, payload };
  }
  function failure(error) {
    return { type: constants.GET_SOUND_OUTPUT_MEDIA_PLAN_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    theService.getSoundOutputMediaPlan(id).then(
      (resp) => {
        if (resp.success) {
          dispatch(success(resp.data));
        }
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };
}

function getDevices(id) {
  function request(id) {
    return { type: constants.GET_ALL_DEVICES_REQUEST, id };
  }
  function success(payload) {
    return { type: constants.GET_ALL_DEVICES_SUCCESS, payload };
  }
  function failure(error) {
    return { type: constants.GET_ALL_DEVICES_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request(id));
    theService.getDevices(id).then(
      (resp) => {
        if (resp.success) {
          dispatch(success(resp.data));
        }
      },
      (error) => {
        dispatch(failure(error));
        dispatch(alertActions.error(error));
      }
    );
  };
}

function startHardwareRealTimeStatus(deviceId) {
  function request(deviceId) {
    return {
      type: signalRConstants.START_REALTIME_HARDWARE_STATUS,
      deviceId,
      signalR: true,
    };
  }

  return (dispatch) => {
    dispatch(request(deviceId));
  };
}

function stopHardwareRealTimeStatus(deviceId) {
  function request(deviceId) {
    return {
      type: signalRConstants.STOP_REALTIME_HARDWARE_STATUS,
      deviceId,
      signalR: true,
    };
  }

  return (dispatch) => {
    dispatch(request(deviceId));
  };
}

function sendPlayerAction(deviceId, soundOutputId, action) {
  function request(deviceId, soundOutputId, action) {
    return {
      type: signalRConstants.SEND_PLAYER_ACTION,
      deviceId,
      soundOutputId,
      playerAction: action,
      signalR: true,
    };
  }

  return (dispatch) => {
    dispatch(request(deviceId, soundOutputId, action));
  };
}

function sendPriorityPlaylistToPlay(
  deviceId,
  soundOutputId,
  playlistId,
  endDate
) {
  function request(deviceId) {
    return {
      type: signalRConstants.SEND_PRIORITY_PLAYLIST_TO_PLAY,
      deviceId,
      soundOutputId,
      playlistId,
      endDate,
      signalR: true,
    };
  }

  return (dispatch) => {
    dispatch(request(deviceId));
  };
}

function stopPriorityPlaylist(deviceId, soundOutputId) {
  function request(deviceId) {
    return {
      type: signalRConstants.STOP_PRIORITY_PLAYLIST,
      deviceId,
      soundOutputId,
      signalR: true,
    };
  }

  return (dispatch) => {
    dispatch(request(deviceId));
  };
}

function setPlayerVolume(deviceId, soundOutputId, volume) {
  function request(deviceId, soundOutputId, volume) {
    return {
      type: signalRConstants.SET_PLAYER_VOLUME,
      deviceId,
      soundOutputId,
      volume,
      signalR: true,
    };
  }

  return (dispatch) => {
    dispatch(request(deviceId, soundOutputId, volume));
  };
}

function clearErrors() {
  function request() {
    return { type: constants.CLEAR_ERRORS };
  }
  return (dispatch) => {
    dispatch(request());
  };
}

function toggleDeviceDetail() {
  function request() {
    return { type: constants.DEVICE_DETAIL_TOGGLE };
  }

  return (dispatch) => {
    dispatch(request());
  };
}

function toggleSoundOutputDetail() {
  function request() {
    return { type: constants.SOUNDOUTPUT_DETAIL_TOGGLE };
  }

  return (dispatch) => {
    dispatch(request());
  };
}

const accountActions = {
  //license
  getAll,
  getById,
  //devices
  getDevices,
  startHardwareRealTimeStatus,
  stopHardwareRealTimeStatus,
  //sound outputs
  getAllSoundOutputs,
  getSoundOutputById,
  sendPlayerAction,
  getSoundOutputMediaPlan,
  setPlayerVolume,
  //playlists
  sendPriorityPlaylistToPlay,
  stopPriorityPlaylist,
  //others
  clearErrors,
  toggleDeviceDetail,
  toggleSoundOutputDetail,
};

export default accountActions;
