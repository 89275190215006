import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import authActions from "../../redux/reducers/authentication/auth.actions";

export class index extends Component {
  constructor(props) {
    super(props);

    this.logoutClick = this.logoutClick.bind(this);
  }

  logoutClick(e) {
    const { logout } = this.props;

    if (e) e.preventDefault();

    this.props.history.push("/login");
    logout();
  }

  render() {
    const { user } = this.props;

    return (
      <div className="topbar">
        <div className="navbar navbar-expand navbar-light navbar-bg justify-content-end">
        <div className="brand"></div>
          <ul className="navbar-nav navbar-align">
            <li className="nav-item dropdown">
              <button
                className="nav-icon pe-0 dropdown-toggle btn btn-link"
                type="button"
                id="dropdownMenuButton"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <div className="position-relative avatar">
                  <i className="las la-user"></i>
                </div>
              </button>
              <div className="dropdown-menu dropdown-menu-end py-0" aria-labelledby="dropdownMenuButton">
                <div className="dropdown-menu-header text-start">
                  <span className="d-block">{user && user.name}</span>
                  <span className="text-muted small">{user && user.email}</span>
                </div>
                <div className="list-group">
                  <a href="/" onClick={this.logoutClick} className="list-group-item text-muted">
                    Logout
                  </a>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { auth } = state;
  const { user } = auth;

  return {
    user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(authActions.logout()),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(index));
