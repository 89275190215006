import React, { Component } from "react";
import propTypes from "prop-types";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { Link, withRouter } from "react-router-dom";
import { isRequired } from "../../FormComponents/Validators";
import authActions from "../../../redux/reducers/authentication/auth.actions";
import { RenderTextInput } from "../../FormComponents/ReduxFormRenders/InputsRenders";
import Alert from "../../FormComponents/Alert";

export class ResetPasswordForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      urlData: {},
    };

    this.showResults = this.showResults.bind(this);
    this.getNewLink = this.getNewLink.bind(this);
    this.loadDataFromUrl = this.loadDataFromUrl.bind(this);
  }

  loadDataFromUrl = () => {
    const { location } = this.props;

    const result = {};
    const query = location.search.substring(1);
    const vars = query.split("&");

    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split("=");
      result[pair[0]] = decodeURIComponent(pair[1]);
    }

    this.setState({ urlData: result });
  };

  componentDidMount() {
    const { doClearErrors } = this.props;

    doClearErrors();
    this.loadDataFromUrl();
  }

  componentDidUpdate(prevProps) {
    const { loadingActivateAccount, requestErrors } = this.props;

    if (prevProps.loadingActivateAccount && !loadingActivateAccount && !requestErrors) {
      const { onSuccess } = this.props;

      if (onSuccess) {
        onSuccess();
      } else {
        this.props.history.push("/");
      }
    }
  }

  getNewLink(e) {
    if (e) e.preventDefault();

    const { resendResetPasswordLink } = this.props;
    const { urlData } = this.state;
    const { email } = urlData;

    if (email) {
      resendResetPasswordLink(email);
    }
  }

  showResults(obj, dispatch, props) {
    const { activateAccount } = props;
    const { urlData } = this.state;
    const { email, token } = urlData;

    if (email && token) {
      activateAccount(email, obj.ac_password, obj.ac_password2, token);
    }
  }

  render() {
    const {
      loadingActivateAccount,
      loadingForgotPassword,
      successForgotPassword,
      isInvalidToken,
      handleSubmit,
      message,
      requestErrors,
    } = this.props;

    return (
      <form onSubmit={handleSubmit(this.showResults)}>
        <div className={successForgotPassword ? "d-none" : ""}>
          <Field
            placeholder="a sua nova password"
            label="Password"
            name="ac_password"
            type="password"
            component={RenderTextInput}
            validate={[isRequired]}
          />
          <Field
            placeholder="repetir a password"
            label="Confirmar Password"
            name="ac_password2"
            type="password"
            component={RenderTextInput}
            validate={[isRequired]}
          />
          <button
            type="submit"
            className="btn btn-primary w-100"
            disabled={loadingActivateAccount || loadingForgotPassword}
          >
            Redefinir Password
          </button>
        </div>
        {message && <Alert className="alert-primary" message={message} />}
        {!isInvalidToken && requestErrors && (
          <Alert className="alert-danger" message={requestErrors.status ? requestErrors.message : requestErrors} />
        )}
        {isInvalidToken && (
          <Alert
            className="small alert-danger"
            message={
              <>
                Não foi possível redefinir a sua password.
                <br />
                <a href="/" onClick={this.getNewLink}>
                  Clique aqui para receber um novo link.
                </a>
              </>
            }
          />
        )}
        <p className="d-block small text-center mt-3 mb-0">
          Voltar ao ecrã de{" "}
          <Link to={"/login"} className="text-decoration-none">
            Login
          </Link>
          .
        </p>
      </form>
    );
  }
}

ResetPasswordForm.propTypes = {
  onSuccess: propTypes.func,
};

const FORM_NAME = "accountform";

ResetPasswordForm = reduxForm({
  form: FORM_NAME,
})(ResetPasswordForm);

const mapStateToProps = (state) => {
  const { auth } = state;
  const { loadingActivateAccount, loadingForgotPassword, successForgotPassword, error, message } = auth;

  return {
    loadingActivateAccount,
    loadingForgotPassword,
    successForgotPassword,
    requestErrors: error,
    message,
    isInvalidToken:
      error && Array.isArray(error.message) && error.message.length > 0 && error.message[0] === "Invalid token.",
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doClearErrors: () => dispatch(authActions.clearErrors()),
    activateAccount: (user, password, confirmPassword, token) => {
      dispatch(authActions.activateAccount(user, password, confirmPassword, token));
    },
    resendResetPasswordLink: (email) => dispatch(authActions.forgotPassword(email)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResetPasswordForm));
