import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { change, Field, formValueSelector, reduxForm, reset } from "redux-form";
import { howManyMinutes, howLongSeconds } from "../../../helpers/howLong";
import {
  Capitalize,
  DateLongFormat,
  DateShortFormat,
  HourShortFormat,
} from "../../../helpers/valuesFormatters";
import accountActions from "../../../redux/reducers/account/account.actions";
import {
  RenderDateTimePicker,
  RenderTimePicker,
} from "../../FormComponents/ReduxFormRenders/InputsRenders";
import { isRequired } from "../../FormComponents/Validators";
import Loading from "../../GlobalComponents/Loading";
import Player from "../../GlobalComponents/Player";
import PlayButton from "../../GlobalComponents/PlayerButton";
import PlayerTime from "../../GlobalComponents/PlayerTime";
import PlaylistCover from "../../GlobalComponents/PlaylistCover";
import MusicCover from "../../GlobalComponents/MusicCover";
import TableComponent from "../../GlobalComponents/TableComponent";
import config from "../../../services/config";

export class SoundOutputDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentVolume: null,
      updatingVolume: false,
      playing: {},
    };

    this.showResults = this.showResults.bind(this);
  }

  componentDidMount() {
    const { soundOutput } = this.props;

    if (
      soundOutput &&
      soundOutput.playerStatus &&
      soundOutput.playerStatus?.volume
    ) {
      const realVolume =
        parseInt(soundOutput.playerStatus?.volume?.replace(/%/g, ""), 10) ?? 0;
      this.setState({ currentVolume: Math.ceil(realVolume / 5) * 5 });
    }
  }

  componentDidUpdate(prevProps) {
    const { soundOutput } = this.props;

    if (
      soundOutput &&
      prevProps.soundOutput?.playerStatus !== soundOutput?.playerStatus &&
      soundOutput.playerStatus?.volume
    ) {
      const realVolume =
        parseInt(soundOutput.playerStatus?.volume?.replace(/%/g, ""), 10) ?? 0;
      this.setState({
        currentVolume: Math.ceil(realVolume / 5) * 5,
        updatingVolume: false,
      });
    }
  }

  setValue = (obj, callback) => {
    this.setState(obj, callback);
  };

  arrowBack = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    this.setState({ audioUrl: false, playing: { stopAudio: true } });
    this.props.toggleSoundOutputDetail();
  };

  playerAction = (action, e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    const { doPlayerAction, deviceId, soundOutputId } = this.props;

    doPlayerAction(deviceId, soundOutputId, action);
  };

  stopPriorityPlaylist = () => {
    const { doStopPriorityPlaylistToPlay, deviceId, soundOutputId } =
      this.props;

    doStopPriorityPlaylistToPlay(deviceId, soundOutputId);

    setTimeout(() => {
      this.setState({
        playlistModalIsOpen: false,
        selectedPlaylist: undefined,
      });
    }, 1500);
  };

  changePlayerVolume = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    const { doSetPlayerVolume, deviceId, soundOutputId } = this.props;
    const { currentVolume } = this.state;

    this.setState({ updatingVolume: true });

    doSetPlayerVolume(deviceId, soundOutputId, parseInt(currentVolume, 10));
  };

  toggleModalPlaylist = (playlist, e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    this.setState({
      showSuccessMessage: false,
      playlistModalIsOpen: !this.state.playlistModalIsOpen,
      selectedPlaylist: playlist,
      audioUrl: false,
      playing: { stopAudio: true },
    });
  };

  showResults(obj, dispatch, props) {
    const { selectedPlaylist } = this.state;
    const {
      resetForm,
      doClearErrors,
      doSendPriorityPlaylistToPlay,
      deviceId,
      soundOutputId,
      formData,
    } = this.props;

    doClearErrors();

    const endDate = new Date(formData.endDate);

    const endDateHour = HourShortFormat(formData.endDateHour).split(":");

    const finalEndDate = new Date(
      endDate.getFullYear(),
      endDate.getMonth(),
      endDate.getDate(),
      endDateHour[0],
      endDateHour[1]
    );

    doSendPriorityPlaylistToPlay(
      deviceId,
      soundOutputId,
      selectedPlaylist.id,
      finalEndDate.toISOString()
    );

    resetForm();

    this.setState({ showSuccessMessage: true });

    setTimeout(() => {
      this.setState({
        showSuccessMessage: false,
        playlistModalIsOpen: false,
        selectedPlaylist: undefined,
      });
    }, 1500);
  }

  render() {
    const {
      playlistModalIsOpen,
      selectedPlaylist,
      currentVolume,
      updatingVolume,
    } = this.state;
    const {
      handleSubmit,
      loading,
      loadingGetSoundOutputById,
      device,
      soundOutput,
      soundOutputDetailOpen,
      soundOutputName,
      endDate,
      endDateHour,
      formData,
      showSuccessMessage,
    } = this.props;

    const playingSpot =
      soundOutput &&
      soundOutput.currentSong &&
      soundOutput.spots.find(
        (s) => s.spotId === soundOutput.currentSong.path.slice(0, -4)
      );

    const playingJingle =
      soundOutput &&
      soundOutput.currentSong &&
      soundOutput.jingles.find(
        (s) => s.jingleId === soundOutput.currentSong.path.slice(0, -4)
      );

    const playingMusic =
      soundOutput &&
      soundOutput.currentSong &&
      soundOutput.playlists
        .reduce((acc, val) => acc.concat(val.musics), [])
        .find((m) => m.id === soundOutput.currentSong.path.slice(0, -4));

    const disablePlayerControls =
      !soundOutput ||
      !soundOutput.mpdConnection ||
      !soundOutput.playerStatus ||
      updatingVolume;

    const isOffline = howLongSeconds(device?.lastUpdate) > 400;

    const tableProps = {
      columns: [
        {
          dataField: "id",
          text: "Id",
          hidden: true,
        },
        {
          dataField: "player",
          text: "Play",
          sort: false,
          classes: "player-button",
          formatExtraData: this.state,
          formatter: (cell, row, rowIndex, formatExtraData) => (
            <PlayButton
              id={row.id}
              url={`/musics/${row.id}.mp3`}
              index={rowIndex}
              playingState={formatExtraData.playing}
              prefix={"music"}
              setState={this.setValue}
            />
          ),
        },
        {
          dataField: "name",
          text: "Nome",
          sort: false,
          headerClasses: "w-50",
          classes: "w-50",
          formatter: (cell, row, rowIndex, formatExtraData) => (
            <>
              <span className="w-90 d-block text-uppercase fw-bold">
                {row.name}
              </span>
              <span className="w-90 lh-1 d-block small">
                {Capitalize(row.artist)}
              </span>
              <span className="w-90 lh-1 d-block small text-muted">
                {Capitalize(row.album)}
              </span>
            </>
          ),
        },
        {
          dataField: "duration",
          text: "Duração",
          sort: false,
          headerClasses: "w-auto text-end",
          classes: "w-auto",
          formatter: (cell, row, rowIndex, formatExtraData) => (
            <span className="w-100 d-block text-end">
              {howManyMinutes(row.duration)}
            </span>
          ),
        },
      ],
    };

    return (
      <div
        className={
          "container soundOutput-container " +
          (soundOutputDetailOpen ? "open" : "")
        }
      >
        <div className="row bg-dark text-white mb-3">
          <div className="col-12 d-flex align-items-center">
            <a
              href="/"
              className="text-white"
              style={{ fontSize: "32px" }}
              onClick={this.arrowBack}
            >
              <i className="las la-arrow-left"></i>
            </a>
            <span className="mb-1 ps-2 lh-1">
              {soundOutputName}
              <br />
              <span className="small">Placa de Som</span>
            </span>
          </div>
        </div>
        {loadingGetSoundOutputById && <Loading />}
        {!loadingGetSoundOutputById && soundOutput && (
          <div className="row">
            {soundOutput.currentSong && (
              <div className="col-12">
                {playingSpot && (
                  <p className="mb-0">SPOT: {playingSpot.name}</p>
                )}
                {playingJingle && (
                  <p className="mb-0">JINGLE: {playingJingle.name}</p>
                )}
                {!playingSpot && !playingJingle && (
                  <div className="d-flex align-items-start">
                    <MusicCover
                      image={
                        playingMusic.pictureUrl
                          ? `${config.cdnUrl}${playingMusic.pictureUrl}`
                          : null
                      }
                    />
                    <div className="ms-2 flex-grow-1">
                      <p className="fw-bold mb-1 lh-1 text-uppercase">
                        {playingMusic.name}
                      </p>
                      <p className="mb-0 lh-1 small">
                        {Capitalize(playingMusic.artist)}
                      </p>
                      <p className="mb-2 lh-1 small text-muted">
                        {Capitalize(playingMusic.album)}
                      </p>
                      <p className="small">
                        <PlayerTime
                          elapsed={soundOutput.playerStatus.elapsed}
                          duration={soundOutput.currentSong.duration}
                        />
                      </p>
                    </div>
                  </div>
                )}
              </div>
            )}
            {!soundOutput.currentSong && (
              <p className="text-cet">
                {isOffline
                  ? `O dispositivo esta offline.`
                  : `A connectar ao player...`}
              </p>
            )}
            <div className="col-12">
              <div className="mt-3 d-flex justify-content-evenly justify-content-md-start">
                <a
                  href="/"
                  className={
                    "bt-player " + (disablePlayerControls ? "disabled" : "")
                  }
                  onClick={this.playerAction.bind(this, "previous")}
                  disabled={disablePlayerControls}
                >
                  <i className={"las la-step-backward"}></i>
                </a>
                <a
                  href="/"
                  className={
                    "bt-player " + (disablePlayerControls ? "disabled" : "")
                  }
                  onClick={this.playerAction.bind(
                    this,
                    soundOutput.playerStatus &&
                      soundOutput.playerStatus.state === "play"
                      ? "pause"
                      : "play"
                  )}
                  disabled={disablePlayerControls}
                >
                  <i
                    className={
                      "las " +
                      (soundOutput.playerStatus &&
                      soundOutput.playerStatus.state === "play"
                        ? "la-pause"
                        : "la-play")
                    }
                  ></i>
                </a>
                <a
                  href="/"
                  className={
                    "bt-player " + (disablePlayerControls ? "disabled" : "")
                  }
                  onClick={this.playerAction.bind(this, "next")}
                  disabled={disablePlayerControls}
                >
                  <i className={"las la-step-forward"}></i>
                </a>
              </div>
            </div>
            <div className="col-12">
              <p className="mb-0 mt-4">
                Volume:
                <select
                  disabled={currentVolume === null || updatingVolume}
                  value={currentVolume || 0}
                  className={"ms-1"}
                  onChange={(e) =>
                    this.setState({ currentVolume: e.target.value })
                  }
                >
                  {[...Array(21)].map((o, i) => (
                    <option key={`vol-${i}`} value={i * 5}>
                      {i * 5}
                    </option>
                  ))}
                </select>
                <a
                  href="/"
                  className={
                    "ms-1 text-decoration-none " +
                    (currentVolume === null || updatingVolume
                      ? "text-muted"
                      : "")
                  }
                  disabled={currentVolume === null || updatingVolume}
                  onClick={this.changePlayerVolume}
                  style={{
                    pointerEvents:
                      currentVolume === null || updatingVolume
                        ? "none"
                        : "auto",
                  }}
                >
                  <i className="las la-sync"></i> Alterar
                </a>
                {updatingVolume && (
                  <em className="ms-1 text-primary small">Updating ...</em>
                )}
              </p>
            </div>
            <div className="col-12 mt-5 mb-5">
              <h3>Playlists</h3>
              <ul className="list-group">
                {soundOutput.playlists.map((playlist, i) => (
                  <li key={"playlist-" + i}>
                    <a
                      href="/"
                      onClick={this.toggleModalPlaylist.bind(this, playlist)}
                      className="list-group-item"
                      style={{
                        cursor: "pointer",
                        minHeight: "60px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div className="row" style={{ pointerEvents: "none" }}>
                        <div className="col-12 d-flex align-items-center">
                          <PlaylistCover
                            images={playlist?.musics
                              ?.filter((m) => m.pictureUrl)
                              .slice(0, 4)
                              .map((m) => `${config.cdnUrl}${m.pictureUrl}`)}
                          />
                          <div>
                            <p className="mb-0 fw-bold lh-1">{playlist.name}</p>
                            {playlist.isDefault && (
                              <span className="badge bg-primary me-1">
                                Predefinida
                              </span>
                            )}
                            {soundOutput.currentPlaylist &&
                              playlist.id ===
                                soundOutput.currentPlaylist.id && (
                                <>
                                  <span className="badge bg-success">
                                    A Reproduzir
                                  </span>
                                  {soundOutput.currentPlaylist
                                    .priorityEndDate && (
                                    <p className="small mt-1 mb-0">
                                      Reproduzir até:{" "}
                                      {DateLongFormat(
                                        soundOutput.currentPlaylist
                                          .priorityEndDate
                                      )}
                                    </p>
                                  )}
                                </>
                              )}
                          </div>
                        </div>
                      </div>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
        <Player
          state={this.state}
          playingState={this.state.playing}
          audioUrl={this.state.audioUrl}
          setState={this.setValue}
        />
        <Modal
          key={"modal-playlist-" + (selectedPlaylist?.id || "")}
          isOpen={playlistModalIsOpen}
          className={"modal-lg m-0"}
          toggle={this.toggleModalPlaylist}
        >
          <ModalHeader
            toggle={this.toggleModalPlaylist}
            className="h6 mb-0"
            close={
              <button
                className="btn-close"
                onClick={this.toggleModalPlaylist}
              ></button>
            }
          >
            <span className="text-muted small me-2 fw-light lh-1">
              PLAYLIST
            </span>
            <br />
            <span className="lh-1">{selectedPlaylist?.name || ""}</span>
          </ModalHeader>
          {selectedPlaylist && (
            <>
              <ModalBody className="row pb-0 pt-0">
                <div className="col-12">
                  {soundOutput?.currentPlaylist &&
                    selectedPlaylist.id === soundOutput.currentPlaylist.id &&
                    soundOutput.currentPlaylist.priorityEndDate && (
                      <div className="row bg-light pt-3 pb-3">
                        <div className="col-12">
                          <p>
                            <i className="las la-info-circle"></i> Esta playlist
                            foi definida como prioritária para tocar até{" "}
                            {DateLongFormat(
                              soundOutput.currentPlaylist.priorityEndDate
                            )}
                          </p>
                          <button
                            type="button"
                            className="btn btn-danger btn-icon"
                            onClick={this.stopPriorityPlaylist}
                          >
                            Parar Playlist <i class="las la-arrow-right"></i>
                          </button>
                        </div>
                      </div>
                    )}

                  {soundOutput?.currentPlaylist?.id &&
                  selectedPlaylist?.id !== soundOutput.currentPlaylist.id ? (
                    <div className="row bg-light pb-3">
                      <div className="col-12">
                        <div className="mt-3">
                          <h4 className="lh-1 mb-2">
                            <i className="las la-play"></i> Reproduzir Playlist
                          </h4>
                          <form onSubmit={handleSubmit(this.showResults)}>
                            <div className="row">
                              <div className="col-12">
                                <p className="small text-muted lh-1">
                                  <i className="las la-info-circle"></i> Defina
                                  até que data e hora a playlist vai reproduzir.
                                </p>
                              </div>
                              <div className="col-12">
                                <Field
                                  label="Data Fim"
                                  name="endDate"
                                  placeholder="ex: 01/10/2021"
                                  type="date"
                                  disabled={loading}
                                  component={RenderDateTimePicker}
                                  min={
                                    new Date(
                                      new Date().getFullYear(),
                                      new Date().getMonth(),
                                      new Date().getDate()
                                    )
                                  }
                                  validate={[isRequired]}
                                />
                              </div>
                              <div className="col-12">
                                <Field
                                  label="Hora Fim"
                                  name="endDateHour"
                                  placeholder="ex: 15:12"
                                  disabled={loading}
                                  component={RenderTimePicker}
                                  validate={[isRequired]}
                                />
                              </div>
                              <div className="col-12">
                                {formData.endDate && formData.endDateHour && (
                                  <p className="text-center small">
                                    Reproduzir playlist até dia {endDate} às{" "}
                                    {endDateHour}
                                  </p>
                                )}
                                <button
                                  type="submit"
                                  disabled={
                                    loading ||
                                    !formData.endDate ||
                                    !formData.endDateHour
                                  }
                                  className="btn btn-dark btn-icon"
                                >
                                  Confirmar{" "}
                                  <i className="las la-arrow-right"></i>
                                </button>
                                {showSuccessMessage && (
                                  <p className="text-success mt-3 lh-1">
                                    Ordem de reprodução enviada para a máquina!
                                  </p>
                                )}
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      className={
                        "row pb-3 " +
                        (!soundOutput?.currentSong
                          ? "bg-light"
                          : "bg-gradient-green text-white")
                      }
                    >
                      <div className="col-12">
                        {!soundOutput?.currentSong && (
                          <div className="mt-3">
                            <Loading />
                          </div>
                        )}
                        {soundOutput?.currentSong &&
                          soundOutput?.currentPlaylist && (
                            <h4 className="lh-1 mt-3 mb-0">
                              <i className="las la-play"></i> Playlist em
                              reprodução ...
                            </h4>
                          )}
                      </div>
                    </div>
                  )}
                  <hr />
                  <div className="row mt-3 mb-5">
                    <div className="col-12">
                      <h4 className="lh-1 mb-2">
                        <i className="las la-music"></i> Músicas
                      </h4>
                      <TableComponent
                        tableKey={"playlist-" + selectedPlaylist?.id}
                        keyField="id"
                        data={selectedPlaylist?.musics}
                        columns={tableProps.columns}
                        disabled={loading}
                        showSearch={false}
                        showColumnsToggle={false}
                        paginationProps={{
                          hideSizePerPage: true,
                          hidePageListOnlyOnePage: true,
                          paginationSize: 3,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </ModalBody>
            </>
          )}
        </Modal>
      </div>
    );
  }
}

const FORM_NAME = "playlistSoundOutputForm";
const selector = formValueSelector(FORM_NAME);
SoundOutputDetail = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: true,
  forceUnregisterOnUnmount: true,
})(SoundOutputDetail);

const mapStateToProps = (state, props) => {
  const { account } = state;
  const { soundOutputId, deviceId } = props;
  const {
    item,
    soundOutputsDetail,
    soundOutputDetailOpen,
    loadingGetSoundOutputById,
  } = account;

  const formData = selector(state, "endDate", "endDateHour");

  return {
    formData,
    endDate: DateShortFormat(formData.endDate),
    endDateHour: HourShortFormat(formData.endDateHour),
    soundOutput: soundOutputsDetail && soundOutputsDetail[soundOutputId],
    soundOutputDetailOpen,
    loadingGetSoundOutputById,
    device:
      item && item.devices && item.devices.find((d) => d.deviceId === deviceId),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doClearErrors: () => dispatch(accountActions.clearErrors()),
    doChange: (y, z) => dispatch(change(FORM_NAME, y, z)),
    resetForm: () => dispatch(reset(FORM_NAME)),
    toggleSoundOutputDetail: () =>
      dispatch(accountActions.toggleSoundOutputDetail()),
    doPlayerAction: (deviceId, soundOutputId, action) => {
      dispatch(
        accountActions.sendPlayerAction(deviceId, soundOutputId, action)
      );
    },
    doSendPriorityPlaylistToPlay: (
      deviceId,
      soundOutputId,
      playlistId,
      endDate
    ) => {
      dispatch(
        accountActions.sendPriorityPlaylistToPlay(
          deviceId,
          soundOutputId,
          playlistId,
          endDate
        )
      );
    },
    doStopPriorityPlaylistToPlay: (deviceId, soundOutputId) => {
      dispatch(accountActions.stopPriorityPlaylist(deviceId, soundOutputId));
    },
    doSetPlayerVolume: (deviceId, soundOutputId, volume) => {
      dispatch(accountActions.setPlayerVolume(deviceId, soundOutputId, volume));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SoundOutputDetail);
