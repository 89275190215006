import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import ResetPasswordForm from "./components/ResetPasswordForm";

class ResetPassword extends Component {
  componentDidMount() {
    const { loggedIn } = this.props;

    if (loggedIn) {
      this.props.history.push("/");
    }
  }

  render() {
    return (
      <div className="container-fluid login-container bg-gradient-aurora h-100">
        <div className="row h-100 justify-content-center">
          <div className="col-12 align-self-center">
            <div className="card text-white bg-dark">
              <div className="card-body">
                <div className="brand"></div>
                <h3 className="card-title fw-light mt-2">Redefinir a Password</h3>
                <p className="mb-4">Coloque a sua nova password para a conta.</p>
                <ResetPasswordForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ResetPassword);
