import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { howLongSeconds } from "../../../helpers/howLong";

function DeviceStatus(props) {
  const [trigger, triggerClock] = useState(false);

  const updateDate = () => {
    triggerClock(!trigger);
  };

  useEffect(() => {
    const intervalID = setInterval(updateDate, 1000);
    return () => clearInterval(intervalID);
  });

  const seconds = props.lastUpdate ? howLongSeconds(props.lastUpdate) : null;
  
  return (
    <div
      role="status"
      className={
        "spinner-border spinner-border-sm dot-status " +
        (props.loading || seconds === null
          ? ""
          : seconds > 480
          ? "bg-danger"
          : seconds > 300
          ? "bg-warning"
          : "bg-success") +
        " " +
        (props.className || "")
      }
    ></div>
  );
}

DeviceStatus.propTypes = {
  loading: PropTypes.bool,
  lastUpdate: PropTypes.string,
  className: PropTypes.string,
};

export default DeviceStatus;
