import authActions from "../authentication/auth.actions";
import SignalRConstants from "../signalR/signalR.constants";

function initializeApp() {
  return (dispatch) => {
    dispatch(authActions.checkSavedLogin());

    if (authActions.isLoggedIn()) {
      dispatch({ type: SignalRConstants.INIT_HUB, signalR: true });
    }
  };
}

const appActions = {
  initializeApp,
};

export default appActions;
