import React, { useEffect } from "react";
import { convertDateUTCToLocale } from "../../../helpers/valuesFormatters";
import DateTimePicker from "react-widgets/lib/DateTimePicker";
import momentLocalizer from "react-widgets-moment";
momentLocalizer();

export const RenderTextInput = ({
  input,
  label,
  help,
  required,
  meta: { touched, error, warning },
  className,
  labelClassName,
  inputClassName,
  ...custom
}) => (
  <div className={"mb-3 " + (className || "")}>
    {label && (
      <label className={labelClassName || "mb-1"} htmlFor={input.name}>
        {label}
        {required ? <span className="ms-1 text-danger">*</span> : ""}
      </label>
    )}
    <input
      {...input}
      //   {...(touched ? (error ? { invalid: true } : { valid: true }) : {})}
      {...custom}
      className={
        "form-control " +
        (inputClassName || "") +
        (input.value ? " filled" : "") +
        (touched && error ? " is-invalid" : "")
      }
    />
    {error && <div className="invalid-feedback">{error}</div>}
    {!error && warning && <div className="form-text">{warning}</div>}
    {help && <div className="form-text text-muted">{help}</div>}
  </div>
);

export const RenderTextAreaInput = ({
  input,
  label,
  help,
  required,
  meta: { touched, error, warning },
  className,
  ...custom
}) => (
  <div className={"mb-3 " + (className || "")}>
    {label && (
      <label className="mb-1" htmlFor={input.name}>
        {label}
        {required ? <span className="ms-1 text-danger">*</span> : ""}
      </label>
    )}
    <textarea
      {...input}
      //   {...(touched ? (error ? { invalid: true } : { valid: true }) : {})}
      {...custom}
      cols={40}
      rows={3}
      className={"form-control" + (input.value ? " filled" : "") + (touched && error ? " is-invalid" : "")}
    />
    {error && <div className="invalid-feedback">{error}</div>}
    {!error && warning && <div className="form-text">{warning}</div>}
    {help && <div className="form-text text-muted">{help}</div>}
  </div>
);

export const RenderDropdownList = ({
  input,
  label,
  help,
  meta: { touched, error, warning },
  placeholder,
  data,
  valueField,
  textField,
  className,
  required,
  ...custom
}) => {
  return (
    <div className={"mb-3 " + (className || "")}>
      {label && (
        <label className="mb-1" htmlFor={input.name}>
          {label}
          {required ? <span className="ms-1 text-danger">*</span> : ""}
        </label>
      )}
      <select
        {...input}
        {...custom}
        className={"form-select" + (input.value ? " filled" : " text-muted") + (touched && error ? " is-invalid" : "")}
      >
        <option value="" disabled>
          {placeholder}
        </option>
        {data && (
          <>
            {valueField &&
              textField &&
              data.map((item, i) => (
                <option key={"op-" + i} value={item[valueField]}>
                  {item[textField]}
                </option>
              ))}
            {!valueField &&
              !textField &&
              data.map((item, i) => (
                <option key={"op-" + i} value={item}>
                  {item}
                </option>
              ))}
          </>
        )}
      </select>
      {error && <div className="invalid-feedback">{error}</div>}
      {!error && warning && <div className="form-text">{warning}</div>}
      {help && <div className="form-text text-muted">{help}</div>}
    </div>
  );
};

export const RenderCheckbox = ({
  id,
  label,
  help,
  input,
  inline,
  disabled,
  className,
  switchStyle,
  checkboxClassName,
  labelClassName,
  meta: { touched, error, warning },
  ...custom
}) => {
  return (
    <div
      className={
        "form-check " + (switchStyle ? "form-switch " : "") + (inline ? "form-check-inline " : "") + (className || "")
      }
    >
      <input
        type="checkbox"
        checked={!!input.value}
        id={id}
        label={label}
        {...input}
        disabled={disabled}
        className={"form-check-input " + (checkboxClassName || "")}
        {...(touched ? (error ? { invalid: true } : {}) : {})}
        {...custom}
      />
      {label && (
        <label className={(disabled ? "text-muted " : "") + (labelClassName || "mb-1")} htmlFor={id}>
          {label}
        </label>
      )}
      {error && <div className="invalid-feedback">{error}</div>}
      {!error && warning && <div className="form-text">{warning}</div>}
      {help && <div className="form-text text-muted">{help}</div>}
    </div>
  );
};

export const RenderFileField = ({ label, file, disabled, input, meta: { error } }) => {
  useEffect(() => {
    if (file) {
      input.onChange(file);
    }
  });

  return (
    <>
      <input type="hidden" name={input.name} disabled />
      {error && <li className="error">{error}</li>}
    </>
  );
};

// export const RenderMultiDatePicker = ({
//   input,
//   label,
//   help,
//   required,
//   meta: { touched, error, warning },
//   className,
//   inputClassName,
//   labelClassName,
//   ...custom
// }) => {
//   const theValue = !input.value ? null : input.value.map((d) => new Date(d));

//   return (
//     <div className={"mb-3 " + (className || "")}>
//       {label && (
//         <label className={labelClassName || "mb-1"} htmlFor={input.name}>
//           {label}
//           {required ? <span className="ms-1 text-danger">*</span> : ""}
//         </label>
//       )}
//       <DatePicker
//         multiple
//         onFocus={input.onFocus}
//         onBlur={() => input.onBlur(undefined, true)}
//         onChange={(e) => {
//           input.onChange(
//             e instanceof Array
//               ? e.map((d) => {
//                   return d instanceof DateObject ? d.format("YYYY-MM-DD") : d;
//                 })
//               : e instanceof DateObject
//               ? e.format("YYYY-MM-DD")
//               : null
//           );
//         }}
//         plugins={[<DatePanel />]}
//         format="DD/MM/YYYY"
//         locale={"pt"}
//         value={theValue}
//         // {...input}
//         {...custom}
//         containerClassName={"p-0 w-100" + (input.value ? " filled" : "") + (touched && error ? " is-invalid" : "")}
//         inputClass={"form-control " + (inputClassName || "")}
//       />
//       {error && <div className="invalid-feedback">{error}</div>}
//       {!error && warning && <div className="form-text">{warning}</div>}
//       {help && <div className="form-text text-muted">{help}</div>}
//     </div>
//   );
// };

export const RenderDateTimePicker = ({
  input,
  label,
  help,
  required,
  meta: { touched, error, warning },
  className,
  inputClassName,
  labelClassName,
  ...custom
}) => {
  const theValue =
    !input.value || input.value === "0001-01-01T00:00:00" ? null : new Date(convertDateUTCToLocale(input.value, true));

  return (
    <div className={"mb-3 " + (className || "")}>
      {label && (
        <label className={labelClassName || "mb-1"} htmlFor={input.name}>
          {label}
          {required ? <span className="ms-1 text-danger">*</span> : ""}
        </label>
      )}
      <DateTimePicker
        onFocus={input.onFocus}
        onBlur={() => input.onBlur(undefined, true)}
        onChange={input.onChange}
        format="DD/MM/YYYY"
        time={false}
        {...custom}
        // defaultCurrentDate={new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())}
        value={theValue}
        culture={"pt"}
        className={
          "p-0 form-control" +
          (input.value ? " filled" : "") +
          (touched && error ? " is-invalid" : "") +
          " " +
          (inputClassName || "")
        }
        containerClassName={"border-0"}
      />
      {error && <div className="invalid-feedback">{error}</div>}
      {!error && warning && <div className="form-text">{warning}</div>}
      {help && <div className="form-text text-muted">{help}</div>}
    </div>
  );
};

export const RenderTimePicker = ({
  input,
  label,
  help,
  required,
  placeholder,
  meta: { touched, error, warning },
  className,
  labelClassName,
  inputClassName,
  inputWrapClassName,
  inputGroup,
  inputGroupButton,
  ...custom
}) => {
  let theDate = input.value;

  if (!(theDate instanceof Date)) {
    theDate = !input.value || input.value === "0001-01-01T00:00:00" ? null : new Date("0001-01-01T" + input.value);
  } else if (theDate && isNaN(theDate.getTime())) {
    theDate = new Date();
    theDate.setHours(10);
  }

  return (
    <div className={"mb-3 " + (className || "")}>
      {label && (
        <label className={labelClassName || "mb-1"} htmlFor={input.name}>
          {label}
          {required ? <span className="ms-1 text-danger">*</span> : ""}
        </label>
      )}
      <div className={(inputGroup ? "input-group has-validation" : "") + " " + (inputWrapClassName || "")}>
        <DateTimePicker
          onChange={input.onChange}
          format={"HH:mm"}
          time={true}
          date={false}
          culture={"pt"}
          value={theDate}
          {...custom}
          className={
            "p-0 form-control " +
            (inputClassName || "") +
            (input.value ? " filled" : "") +
            (touched && error ? " is-invalid" : "")
          }
          containerClassName={"border-0"}
          placeholder={placeholder}
        />
        {inputGroupButton}
        {error && <div className="invalid-feedback">{error}</div>}
      </div>
      {!error && warning && <div className="form-text">{warning}</div>}
      {help && <div className="form-text text-muted">{help}</div>}
    </div>
  );
};

// export const RenderInputMask = ({
//   input,
//   label,
//   help,
//   required,
//   labelClassName,
//   meta: { touched, error, warning },
//   className,
//   ...props
// }) => (
//   <div className={"mb-3 " + (className || "")}>
//     {label && (
//       <label className={labelClassName || "mb-1"} htmlFor={input.name}>
//         {label}
//         {required ? <span className="ms-1 text-danger">*</span> : ""}
//       </label>
//     )}
//     <MaskedInput
//       {...props}
//       {...input}
//       className={"form-control" + (input.value ? " filled" : "") + (touched && error ? " is-invalid" : "")}
//     />
//     {error && <div className="invalid-feedback">{error}</div>}
//     {!error && warning && <div className="form-text">{warning}</div>}
//     {help && <div className="form-text text-muted">{help}</div>}
//   </div>
// );

// export const RenderIPInputMask = ({
//   input,
//   label,
//   help,
//   required,
//   labelClassName,
//   meta: { touched, error, warning },
//   className,
//   ...props
// }) => {
//   const propsInput = {
//     guide: false,
//     placeholderChar: "\u2000",
//     mask: (value) => Array(value.length).fill(/[\d.]/),
//     pipe: (value) => {
//       if (value === "." || value.endsWith("..")) return false;

//       const parts = value.split(".");

//       if (parts.length > 4 || parts.some((part) => part === "00" || part < 0 || part > 255)) {
//         return false;
//       }

//       return value;
//     },
//   };

//   return (
//     <div className={"mb-3 " + (className || "")}>
//       {label && (
//         <label className={labelClassName || "mb-1"} htmlFor={input.name}>
//           {label}
//           {required ? <span className="ms-1 text-danger">*</span> : ""}
//         </label>
//       )}
//       <MaskedInput
//         {...propsInput}
//         {...props}
//         {...input}
//         className={"form-control w-auto " + (input.value ? " filled" : "") + (touched && error ? " is-invalid" : "")}
//       />
//       {error && <div className="invalid-feedback">{error}</div>}
//       {!error && warning && <div className="form-text">{warning}</div>}
//       {help && <div className="form-text text-muted">{help}</div>}
//     </div>
//   );
// };
