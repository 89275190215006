import { baseService } from "../base.service";

const baseRoute = "my/";

function getById(id) {
  return baseService.getById(id, baseRoute + "licenses/");
}

function getSoundOutputById(id) {
  return baseService.getById(id, baseRoute + "soundoutput/");
}

function getAll() {
  return baseService.getAll(baseRoute + "licenses/");
}

function getAllSoundOutputs(brandId, licenseId) {
  let query = "";
  if (brandId) query += "&brandId=" + brandId;
  if (licenseId) query += "&licenseId=" + licenseId;

  return baseService.getAll(baseRoute + `soundoutputs${query ? "?" + query.substring(1) : ""}`);
}

function getDevices(id) {
  return baseService.getAll(baseRoute + id + "/devices");
}

function setPlaylistDefaultInSoundOutput(playlistId, soundOutputId) {
  return baseService.update({}, `${baseRoute}soundOutput/${soundOutputId}/playlist/${playlistId}/default`);
}

function getSoundOutputMediaPlan(soundOutputId) {
  return baseService.getAll(`${baseRoute}soundOutput/${soundOutputId}/plan`);
}

export const accountService = {
  //license
  getById,
  getAll,
  //devices
  getDevices,
  //playlist
  setPlaylistDefaultInSoundOutput,
  //sound outputs
  getSoundOutputById,
  getAllSoundOutputs,
  getSoundOutputMediaPlan,
};

export default accountService;
