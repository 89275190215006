import constants from "./auth.constants";

const initialState = {
  loadingLogin: false,
  loadingForgotPassword: false,
  loadingCreateAccount: false,
  successForgotPassword: false,
  successResendActivation: false,
};

const clearErrors = {
  message: undefined,
  error: undefined,
  successForgotPassword: false,
  successResendActivation: false,
};

export function auth(state = initialState, action) {
  switch (action.type) {
    //login
    case constants.LOGIN_REQUEST:
      return Object.assign({}, state, { loggedIn: false, loadingLogin: true, user: action.user }, clearErrors);
    case constants.LOGIN_SUCCESS:
      return Object.assign({}, state, {
        loadingLogin: false,
        user: action.user,
        roles: action.roles,
        loggedIn: action.user && true,
      });
    case constants.LOGIN_FAILURE:
      return Object.assign({}, state, {
        loadingLogin: false,
        user: null,
        roles: null,
        loggedIn: false,
        error: action.error,
      });

    //reset password
    case constants.FORGOTPASSWORD_REQUEST:
      return Object.assign({}, state, { loadingForgotPassword: true, user: action.user }, clearErrors);
    case constants.FORGOTPASSWORD_SUCCESS:
      return Object.assign({}, state, {
        loadingForgotPassword: false,
        successForgotPassword: true,
        message: action.message,
      });
    case constants.FORGOTPASSWORD_FAILURE:
      return Object.assign({}, state, {
        loadingForgotPassword: false,
        successForgotPassword: false,
        error: action.error,
      });

    //logout
    case constants.LOGOUT_REQUEST:
      return Object.assign({}, state, { user: null, roles: null, loggedIn: false }, clearErrors);

    //create account
    case constants.CREATE_CLIENT_REQUEST:
      return Object.assign({}, state, { loadingCreateAccount: true, user: action.user }, clearErrors);
    case constants.CREATE_CLIENT_SUCCESS:
      return Object.assign({}, state, {
        loadingCreateAccount: false,
      });
    case constants.CREATE_CLIENT_FAILURE:
      return Object.assign({}, state, { loadingCreateAccount: false, error: action.error });

    //activate account
    case constants.ACTIVATEACCOUNT_REQUEST:
      return Object.assign({}, state, { loadingActivateAccount: true, user: action.user }, clearErrors);
    case constants.ACTIVATEACCOUNT_SUCCESS:
      return Object.assign({}, state, {
        loadingActivateAccount: false,
        user: action.user,
        roles: action.roles,
        loggedIn: true,
      });
    case constants.ACTIVATEACCOUNT_FAILURE:
      return Object.assign({}, state, { loadingActivateAccount: false, error: action.error });

    //resend activation
    case constants.RESENDACTIVATION_REQUEST:
      return Object.assign({}, state, { loadingResendActivation: true, user: action.user }, clearErrors);
    case constants.RESENDACTIVATION_SUCCESS:
      return Object.assign({}, state, {
        loadingResendActivation: false,
        successResendActivation: true,
        message: action.message,
      });
    case constants.RESENDACTIVATION_FAILURE:
      return Object.assign({}, state, {
        loadingResendActivation: false,
        successResendActivation: false,
        error: action.error,
      });

    case constants.CLEAR_ERRORS:
      return Object.assign({}, state, clearErrors);

    default:
      return state;
  }
}

export default auth;
