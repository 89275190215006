import React from "react";
import { Link, useRouteMatch } from "react-router-dom";
import AdminRoutes from "../../routes/admin.routes";

function ActionMenu() {
  const match = useRouteMatch();
  const currentRoute = Object.values(AdminRoutes).find((r) => r.path === match.path);

  if (!currentRoute || currentRoute.hideActionMenu) return <></>;

  return (
    <div className="action-menu bg-light container-fluid">
      <div className="row pt-2 pb-2">
        <div className="col-12 d-flex flex-wrap">
          <h5 className="current-title d-inline-block mb-0 fw-bold">{currentRoute.title}</h5>
          <div className="actions-links ms-auto">
            {currentRoute &&
              currentRoute.topMenuActions &&
              currentRoute.topMenuActions.map((routeKey, i) => {
                return AdminRoutes[routeKey] ? (
                  <Link
                    key={"menu-action-" + i}
                    className="ps-3 d-inline-block text-decoration-none"
                    to={AdminRoutes[routeKey].path.replace(':id', match.params.id)}
                  >
                    {AdminRoutes[routeKey].title}
                  </Link>
                ) : (
                  <span key={"menu-action-" + i}></span>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ActionMenu;
