import React, { useEffect, useRef, useState } from "react";

export default function Player({ state, playingState, setState, audioUrl }) {
  const [currentAudioUrl, setCurrentAudioUrl] = useState(audioUrl);
  const audioEl = useRef(null);

  useEffect(() => {
    // if the audio element is not null
    if (audioEl?.current) {
      // set loading to true
      setState({ loading: true });
      // set the current audio url
      setCurrentAudioUrl(audioUrl);
      // update the audio element's src
      audioEl.current.src = audioUrl;
      // load the audio
      audioEl.current.load();
      // add an event listener to the audio element
      audioEl.current.addEventListener("ended", () => {
        // when the audio element ends, set the playing state to false
        setState({ loading: false });
      });
      // set loading to false
      setState({ loading: false });
    }
  }, [audioUrl]);

  useEffect(() => {
    // if the audio element is not null
    if (
      audioEl?.current &&
      playingState &&
      Object.keys(playingState).length > 0
    ) {
      // if the playing state is true
      if (state["isPlaying"] && currentAudioUrl === audioUrl) {
        audioEl.current.pause();
        setState({ isPlaying: false });
      } else {
        const playPromise = audioEl.current.play();
        if (playPromise !== undefined) {
          playPromise
            .then((_) => {
              // Automatic playback started!
              // Show playing UI.
              console.log("audio played auto");
            })
            .catch((error) => {
              // Auto-play was prevented
              // Show paused UI.
              console.log("playback prevented");
            });
        }
        setState({ isPlaying: true });
      }
    }
  }, [playingState]);

  return (
    <>
      <audio
        ref={audioEl}
        id={"player"}
        className="d-none player"
        src={audioUrl || ""}
        controls
      />
    </>
  );
}
