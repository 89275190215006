import React, { useEffect, useState } from "react";
import { howManyMinutes } from "../../helpers/howLong";

const PlayerTime = (props) => {
  const [minutes, setMinutes] = useState(props.elapsed);
  const [duration, setDuration] = useState(props.duration);

  const updateDate = () => {
    setMinutes(minutes + 1);
  };

  useEffect(() => {
    const intervalID = setInterval(updateDate, 1000);
    return () => clearInterval(intervalID);
  });

  useEffect(() => {
    setMinutes(props.elapsed);
    setDuration(props.duration);
  }, [props.elapsed, props.duration]);

  return <span key={"player-time-" + props.timerKey}>{howManyMinutes(minutes) + "/" + howManyMinutes(duration)}</span>;
};

export default PlayerTime;
