export function authHeader() {
  let id_token = getToken();

  if (id_token) {
    return { Authorization: "Bearer " + id_token };
  } else {
    return {};
  }
}

export function getToken() {
  if (typeof localStorage !== `undefined`) return localStorage.getItem("id_token");
  return "";
}

const defaultHeaders = () => {
  return { ...authHeader(), "Content-Type": "application/json" };
};

export const putHeader = () => ({
  method: "PUT",
  headers: defaultHeaders(),
});

export const postHeader = () => ({
  method: "POST",
  headers: defaultHeaders(),
});

export const deleteHeader = () => ({
  method: "DELETE",
  headers: defaultHeaders(),
});

export const getHeader = () => ({
  method: "GET",
  headers: defaultHeaders(),
});

export function getHeaderForUploads(obj) {
  const formData = new FormData();

  for (const name in obj) {
    if (Array.isArray(obj[name])) {
      obj[name].forEach(item => {
        formData.append(name, item);
      });
    } else {
      formData.append(name, obj[name]);
    }
  }

  const requestOptions = {
    method: "POST",
    headers: { ...authHeader() },
    body: formData,
  };

  return requestOptions;
}

export const Headers = {
  putHeader,
  postHeader,
  deleteHeader,
  getHeader,
  getHeaderForUploads,
};
