import React, { Component } from "react";
import { connect } from "react-redux";
import alertActions from "../../redux/reducers/alerts/alerts.actions";
import ToastAlert from "./ToastAlert";

export class ToastContainer extends Component {
  render() {
    const { alerts } = this.props;

    return (
      <div className={"toast-container position-absolute end-0 top-0"}>
        {alerts &&
          alerts.map((alert, i) => (
            <ToastAlert
              key={"t-" + Math.random()}
              icon={alert.type}
              date={alert.date}
              closeHandler={() => this.props.doClear(i)}
              header={alert.type === "danger" ? "Operação Inválida" : alert.type === "warning" ? "Atenção" : "Sucesso"}
              body={
                alert.messages && alert.messages.message
                  ? Array.isArray(alert.messages.message) && alert.messages.message.length > 0
                    ? alert.messages.message[0]
                    : alert.messages.message || ""
                  : alert.messages || ""
              }
            />
          ))}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { alerts } = state;

  return {
    alerts: alerts.alertMessages,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doClear: (index) => dispatch(alertActions.clear(index)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ToastContainer);
