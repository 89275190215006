import React, { Component } from "react";
import { connect } from "react-redux";
import accountActions from "../../../redux/reducers/account/account.actions";
import Loading from "../../GlobalComponents/Loading";
import DeviceStatus from "./DeviceStatus";
import SoundOutputDetail from "./SoundOutputDetail";

export class DeviceDetail extends Component {
  constructor(props) {
    super();

    this.state = {};
  }

  arrowBack = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    this.props.toggleDeviceDetail();
  };

  soundOutputClick = (soundOutputId, soundOutputName, e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    this.setState({ soundOutputId, soundOutputName });
    this.props.doGetSoundOutputById(soundOutputId);
    this.props.toggleSoundOutputDetail();
  };

  render() {
    const { soundOutputId, soundOutputName } = this.state;
    const { loadingGetById, item, device, deviceNummber, deviceDetailOpen } =
      this.props;

    return (
      <div
        className={
          "container device-container  " + (deviceDetailOpen ? "open" : "")
        }
      >
        {loadingGetById && <Loading />}
        {!loadingGetById && item && device && (
          <>
            <div className="row bg-gradient-green text-white">
              <div className="col-12 d-flex align-items-center">
                <a
                  href="/"
                  className="text-white"
                  style={{ fontSize: "32px" }}
                  onClick={this.arrowBack}
                >
                  <i className="las la-arrow-left"></i>
                </a>
                <span className="mb-1 ps-2 lh-1">
                  {item.brandName + " - " + item.licenseName}
                  <br />
                  <span className="small">Licença</span>
                </span>
                <span className="mb-1 ps-2 lh-1 border-start ms-2">
                  {"#MÁQUINA " + deviceNummber}
                  <br />
                  <span className="small">{device.serialNumber}</span>
                </span>
              </div>
            </div>
            <div
              className="row bg-gray"
              style={{ height: "calc(100vh - 48px)" }}
            >
              <div className="col-12 mt-2">
                <small className="d-block mb-2 text-muted">
                  {device && device.soundOutputs && device.soundOutputs.length}{" "}
                  placas de som
                </small>
                <ul className="list-group">
                  {device &&
                    device.soundOutputs &&
                    device.soundOutputs.map((soundOutput, k) => (
                      <li
                        key={"soundOutput-" + k}
                        className="list-group-item"
                        onClick={this.soundOutputClick.bind(
                          this,
                          soundOutput.id,
                          soundOutput.name
                        )}
                      >
                        <div className="mb-0 d-flex align-items-center cursor-pointer">
                          <DeviceStatus
                            key={"ds-" + device.deviceId}
                            className="me-1"
                            lastUpdate={device.lastUpdate}
                          />
                          <i className="h5 mb-0 las me-1 la-volume-up"></i>
                          <span className="fw-bold">{soundOutput.name}</span>
                        </div>
                        <div className="d-flex justify-content-between">
                          <span className="small text-muted">
                            Placa de Som #{k + 1}
                          </span>
                          <p className="d-flex align-items-center mb-0 small text-muted">
                            <i className="h5 mb-0 las la-music me-1"></i>
                            <span className="lh-1">
                              {soundOutput.totalPlaylists}
                            </span>
                            <i className="h5 mb-0 las la-bell ms-2 me-1"></i>
                            <span className="lh-1">
                              {soundOutput.totalJingles}
                            </span>
                            <i className="h5 mb-0 las la-ad ms-2 me-1"></i>
                            <span className="lh-1">
                              {soundOutput.totalSpots}
                            </span>
                          </p>
                        </div>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
            <SoundOutputDetail
              soundOutputId={soundOutputId}
              soundOutputName={soundOutputName}
              deviceId={device.deviceId}
            />
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { account } = state;
  const { currentDeviceId } = props;
  const { item, deviceDetailOpen, loadingGetById } = account;

  return {
    item,
    deviceDetailOpen,
    device:
      item &&
      item.devices &&
      item.devices.find((d) => d.deviceId === currentDeviceId),
    deviceNummber:
      item &&
      item.devices &&
      item.devices.findIndex((d) => d.deviceId === currentDeviceId) + 1,
    loadingGetById,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doGetSoundOutputById: (id) => {
      dispatch(accountActions.getSoundOutputById(id));
    },
    toggleDeviceDetail: () => dispatch(accountActions.toggleDeviceDetail()),
    toggleSoundOutputDetail: () =>
      dispatch(accountActions.toggleSoundOutputDetail()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceDetail);
