import decode from "jwt-decode";
import baseService from "../base.service";
import config from "../config";
import { postHeader, putHeader } from "../helpers";

export const authService = {
  login,
  getToken,
  logout,
  loggedIn,
  getRoles,
  getProfile,
  forgotPassword,
  changePassword,
  activateAccount,
  resendActivationLink,
  resetPassword,
  setToken,
};

const baseRoute = "account/";

function login(email, password) {
  var details = {
    password: password,
    email: email,
  };

  const requestOptions = {
    ...postHeader(),
    body: JSON.stringify(details),
  };

  return fetch(`${config.apiUrl}account/login/`, requestOptions)
    .then(baseService.handleResponse, baseService.handleError)
    .then((user) => {
      // login successful if there's a jwt token in the response
      if (user && user.token) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        setToken(user.token);
        //localStorage.setItem('user', JSON.stringify(user));
      }
      return { profile: getProfile(), roles: getRoles() };
    });
}

function getProfile() {
  const token = getToken();

  if (!token) return {};

  try {
    const tokenDecoded = decode(token);

    return {
      userId: tokenDecoded.userId,
      email: tokenDecoded["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"] || "",
      name: tokenDecoded.fullName || "",
      createdDate: tokenDecoded.createdDate || "",
    };
  } catch (error) {
    return {};
  }
}

function getRoles() {
  const token = getToken();
  if (!token) return [];
  try {
    const roles = decode(token)["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
    return roles instanceof Array ? roles : roles.split();
  } catch (error) {}
}

function loggedIn() {
  // Checks if there is a saved token and it's still valid
  const token = getToken(); // GEtting token from localstorage
  return !!token && !isTokenExpired(token); // handwaiving here
}

function isTokenExpired(token) {
  try {
    const decoded = decode(token);

    if (decoded.exp < Date.now() / 1000) {
      // Checking if token is expired. N
      return true;
    }
    return false;
  } catch (err) {
    return false;
  }
}

function setToken(idToken) {
  // Saves user token to localStorage
  localStorage.setItem("id_token", idToken);
}

function getToken() {
  // Retrieves the user token from localStorage
  if (typeof localStorage !== `undefined`) return localStorage.getItem("id_token");

  return "";
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("id_token");
}

function activateAccount(obj) {
  return resetPassword(obj);
}

function resetPassword(obj) {
  const requestOptions = {
    ...postHeader(),
    body: JSON.stringify(obj),
  };

  return fetch(`${config.apiUrl + baseRoute}resetPassword`, requestOptions)
    .then(baseService.handleResponse, baseService.handleError)
    .then((resp) => {
      if (resp && resp.data) {
        setToken(resp.data);
      }

      return { profile: getProfile(), roles: getRoles() };
    });
}

function resendActivationLink(email) {
  const requestOptions = {
    ...postHeader(),
  };

  return fetch(`${config.apiUrl + baseRoute}resendActivationLink/${email}`, requestOptions).then(
    baseService.handleResponse,
    baseService.handleError
  );
}

function forgotPassword(email) {
  const requestOptions = {
    ...postHeader(),
  };

  return fetch(`${config.apiUrl + baseRoute}forgotPassword/${email}`, requestOptions).then(
    baseService.handleResponse,
    baseService.handleError
  );
}

function changePassword(oldPassword, newPassword) {
  const requestOptions = {
    ...putHeader(),
    body: JSON.stringify({ oldPassword, newPassword }),
  };

  return fetch(`${config.apiUrl + baseRoute}changePassword`, requestOptions).then(
    baseService.handleResponse,
    baseService.handleError
  );
}

export default authService;
