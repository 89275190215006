const accountConstants = {
  GET_ALL_REQUEST: "LICENSES_GET_ALL_REQUEST",
  GET_ALL_SUCCESS: "LICENSES_GET_ALL_SUCCESS",
  GET_ALL_FAILURE: "LICENSES_GET_ALL_FAILURE",

  GET_BY_ID_REQUEST: "LICENSES_GET_BY_ID_REQUEST",
  GET_BY_ID_SUCCESS: "LICENSES_GET_BY_ID_SUCCESS",
  GET_BY_ID_FAILURE: "LICENSES_GET_BY_ID_FAILURE",

  GET_ALL_DEVICES_REQUEST: "LICENSES_GET_ALL_DEVICES_REQUEST",
  GET_ALL_DEVICES_SUCCESS: "LICENSES_GET_ALL_DEVICES_SUCCESS",
  GET_ALL_DEVICES_FAILURE: "LICENSES_GET_ALL_DEVICES_FAILURE",

  GET_SOUND_OUTPUT_BY_ID_REQUEST: "LICENSES_GET_SOUND_OUTPUT_BY_ID_REQUEST",
  GET_SOUND_OUTPUT_BY_ID_SUCCESS: "LICENSES_GET_SOUND_OUTPUT_BY_ID_SUCCESS",
  GET_SOUND_OUTPUT_BY_ID_FAILURE: "LICENSES_GET_SOUND_OUTPUT_BY_ID_FAILURE",

  GET_ALL_SOUND_OUTPUTS_REQUEST: "LICENSES_GET_ALL_SOUND_OUTPUTS_REQUEST",
  GET_ALL_SOUND_OUTPUTS_SUCCESS: "LICENSES_GET_ALL_SOUND_OUTPUTS_SUCCESS",
  GET_ALL_SOUND_OUTPUTS_FAILURE: "LICENSES_GET_ALL_SOUND_OUTPUTS_FAILURE",

  GET_SOUND_OUTPUT_MEDIA_PLAN_REQUEST: "LICENSES_GET_SOUND_OUTPUT_MEDIA_PLAN_REQUEST",
  GET_SOUND_OUTPUT_MEDIA_PLAN_SUCCESS: "LICENSES_GET_SOUND_OUTPUT_MEDIA_PLAN_SUCCESS",
  GET_SOUND_OUTPUT_MEDIA_PLAN_FAILURE: "LICENSES_GET_SOUND_OUTPUT_MEDIA_PLAN_FAILURE",

  UPDATE_MEDIA_STATUS_REQUEST: "LICENSES_UPDATE_MEDIA_STATUS_REQUEST",
  UPDATE_MEDIA_STATUS_SUCCESS: "LICENSES_UPDATE_MEDIA_STATUS_SUCCESS",
  UPDATE_MEDIA_STATUS_FAILURE: "LICENSES_UPDATE_MEDIA_STATUS_FAILURE",

  ASSOCIATE_PLAYLIST_TO_SOUND_OUTPUT: "LICENSES_ASSOCIATE_PLAYLIST_TO_SOUND_OUTPUT",
  UPDATE_PLAYLIST_IN_SOUND_OUTPUT: "LICENSES_UPDATE_PLAYLIST_IN_SOUND_OUTPUT",
  DISASSOCIATE_PLAYLIST_FROM_SOUND_OUTPUT: "LICENSES_DISASSOCIATE_PLAYLIST_FROM_SOUND_OUTPUT",

  SET_PLAYLIST_DEFAULT_IN_SOUND_OUTPUT_REQUEST: "LICENSES_SET_PLAYLIST_DEFAULT_IN_SOUND_OUTPUT_REQUEST",
  SET_PLAYLIST_DEFAULT_IN_SOUND_OUTPUT_SUCCESS: "LICENSES_SET_PLAYLIST_DEFAULT_IN_SOUND_OUTPUT_SUCCESS",
  SET_PLAYLIST_DEFAULT_IN_SOUND_OUTPUT_FAILURE: "LICENSES_SET_PLAYLIST_DEFAULT_IN_SOUND_OUTPUT_FAILURE",

  UPDATE_REMOTE_STATUS: "LICENSES_UPDATE_REMOTE_STATUS",
  UPDATE_REMOTE_HARDWARE_STATUS: "LICENSES_UPDATE_REMOTE_HARDWARE_STATUS",
  UPDATE_REMOTE_PLAYERS_STATUS: "LICENSES_UPDATE_REMOTE_PLAYERS_STATUS",

  CLEAR_ERRORS: "LICENSES_CLEAR_ERRORS",
  DEVICE_DETAIL_TOGGLE: "DEVICE_DETAIL_TOGGLE",
  SOUNDOUTPUT_DETAIL_TOGGLE: "SOUNDOUTPUT_DETAIL_TOGGLE",
};

export default accountConstants;
