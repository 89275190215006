import constants from "./account.constants";
import { GlobalInitialState } from "../global";

const initialState = {
  ...GlobalInitialState,
  soundOutputsDetail: {},
  hitAnyDevice: false,
};
const clearErrors = {
  message: undefined,
  error: undefined,
  itemOTRS: undefined,
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    //GET ALL
    case constants.GET_ALL_REQUEST:
      return Object.assign({}, state, {
        loadingGetAll: true,
        hitAnyDevice: false,
      });
    case constants.GET_ALL_SUCCESS:
      return Object.assign({}, state, {
        loadingGetAll: false,
        items: action.payload,
      });
    case constants.GET_ALL_FAILURE:
      return Object.assign({}, state, {
        loadingGetAll: false,
        error: action.error,
      });

    //GET BY ID
    case constants.GET_BY_ID_REQUEST:
      return Object.assign({}, state, {
        loadingGetById: true,
        loadingGetLicenseId: action.id,
      });
    case constants.GET_BY_ID_SUCCESS:
      return Object.assign({}, state, {
        loadingGetById: false,
        loadingGetLicenseId: undefined,
        item: action.payload,
        items: state.items
          ? state.items.map((license) => {
              if (license.licenseId === action.payload.licenseId) {
                return {
                  ...license,
                  ...action.payload,
                };
              }

              return license;
            })
          : [],
      });
    case constants.GET_BY_ID_FAILURE:
      return Object.assign({}, state, {
        loadingGetById: false,
        loadingGetLicenseId: undefined,
        error: action.error,
      });

    case constants.UPDATE_REMOTE_STATUS:
      return Object.assign({}, state, {
        startUpdateRemote: true,
        hitAnyDevice: true,
        items: state.items
          ? action.data && action.data.clientId
            ? state.items.map((license) => {
                return {
                  ...license,
                  devices: license?.devices?.map((device) => {
                    if (device.deviceId === action.data.clientId) {
                      return { ...device, lastUpdate: action.data.lastUpdate };
                    }

                    return device;
                  }),
                };
              })
            : state.items
          : [],
        item: {
          ...state.item,
          devices: state.item?.devices?.map((device) => {
            if (device.deviceId === action.data.clientId) {
              return { ...device, lastUpdate: action.data.lastUpdate };
            }

            return device;
          }),
        },
      });

    case constants.UPDATE_REMOTE_HARDWARE_STATUS:
      return Object.assign({}, state, {
        devices: state.devices
          ? state.devices.map((item) => {
              if (item.deviceId === action.data.clientId) {
                return {
                  ...item,
                  hardwareStatus: action.data.hardwareStatus,
                  lastUpdate: new Date(new Date().getTime()).toISOString(),
                };
              } else return item;
            })
          : [],
      });

    case constants.UPDATE_REMOTE_PLAYERS_STATUS:
      let newSoundOutputsDetails = state.soundOutputsDetail
        ? { ...state.soundOutputsDetail }
        : {};

      // find soundoutput in item devices
      let deviceToUpdate = state.item?.devices?.find(
        (d) => d.deviceId === action.data.clientId
      );

      if (deviceToUpdate) {
        deviceToUpdate.soundOutputs.forEach((item, i) => {
          newSoundOutputsDetails[item.id] = {
            spots: [],
            playlists: [],
            jingles: [],
            ...item,
            ...newSoundOutputsDetails[item.id],
            ...action.data.soundOutputs[i],
          };
        });
      }

      return Object.assign({}, state, {
        soundOutputsDetail: newSoundOutputsDetails,
      });

    case constants.GET_ALL_DEVICES_REQUEST:
      return Object.assign({}, state, { loadingGetDevices: true });
    case constants.GET_ALL_DEVICES_SUCCESS:
      return Object.assign({}, state, {
        loadingGetDevices: false,
        devices: action.payload,
        clearErrors,
      });
    case constants.GET_ALL_DEVICES_FAILURE:
      return Object.assign({}, state, {
        loadingGetDevices: false,
        error: action.error,
      });

    case constants.GET_ALL_SOUND_OUTPUTS_REQUEST:
      return Object.assign({}, state, { loadingGetSoundOutputs: true });
    case constants.GET_ALL_SOUND_OUTPUTS_SUCCESS:
      return Object.assign({}, state, {
        loadingGetSoundOutputs: false,
        soundOutputs: action.payload,
        clearErrors,
      });
    case constants.GET_ALL_SOUND_OUTPUTS_FAILURE:
      return Object.assign({}, state, {
        loadingGetSoundOutputs: false,
        error: action.error,
      });

    case constants.GET_SOUND_OUTPUT_BY_ID_REQUEST:
      return Object.assign({}, state, { loadingGetSoundOutputById: true });
    case constants.GET_SOUND_OUTPUT_BY_ID_SUCCESS:
      let newSoundOutputs = state.soundOutputsDetail
        ? { ...state.soundOutputsDetail }
        : {};
      newSoundOutputs[action.payload.id] = newSoundOutputs[action.payload.id]
        ? { ...newSoundOutputs[action.payload.id], ...action.payload }
        : action.payload;

      return Object.assign({}, state, {
        loadingGetSoundOutputById: false,
        soundOutputsDetail: newSoundOutputs,
      });
    case constants.GET_SOUND_OUTPUT_BY_ID_FAILURE:
      return Object.assign({}, state, {
        loadingGetSoundOutputById: false,
        error: action.error,
      });

    case constants.GET_SOUND_OUTPUT_MEDIA_PLAN_REQUEST:
      return Object.assign({}, state, { loadingGetSoundOutputMediaPlan: true });
    case constants.GET_SOUND_OUTPUT_MEDIA_PLAN_SUCCESS:
      return Object.assign({}, state, {
        loadingGetSoundOutputMediaPlan: false,
        mediaPlan: action.payload,
      });
    case constants.GET_SOUND_OUTPUT_MEDIA_PLAN_FAILURE:
      return Object.assign({}, state, {
        loadingGetSoundOutputMediaPlan: false,
        error: action.error,
      });

    // SET PLAYLIST DEFAULT
    case constants.SET_PLAYLIST_DEFAULT_IN_SOUND_OUTPUT_REQUEST:
      return Object.assign({}, state, { loadingSetPlaylistDefault: true });
    case constants.SET_PLAYLIST_DEFAULT_IN_SOUND_OUTPUT_SUCCESS:
      let newPlaylitsDefaultInSoundOutputsDetail = state.soundOutputsDetail
        ? { ...state.soundOutputsDetail }
        : {};

      if (
        newPlaylitsDefaultInSoundOutputsDetail &&
        newPlaylitsDefaultInSoundOutputsDetail[action.payload.soundOutputId]
      ) {
        newPlaylitsDefaultInSoundOutputsDetail[action.payload.soundOutputId] = {
          ...state.soundOutputsDetail[action.payload.soundOutputId],
          playlists: state.soundOutputsDetail[
            action.payload.soundOutputId
          ].playlists.map((item) => {
            return {
              ...item,
              isDefault: item.id === action.payload.playlistId ? true : false,
            };
          }),
        };
      }

      return Object.assign({}, state, {
        loadingSetPlaylistDefault: false,
        soundOutputsDetail: newPlaylitsDefaultInSoundOutputsDetail,
      });
    case constants.SET_PLAYLIST_DEFAULT_IN_SOUND_OUTPUT_FAILURE:
      return Object.assign({}, state, {
        loadingSetPlaylistDefault: false,
        error: action.error,
      });

    // UPDATE PLAYLIST IN SOUND OUTPUT
    case constants.UPDATE_PLAYLIST_IN_SOUND_OUTPUT:
      let updatePlaylitsInSoundOutputsDetail = state.soundOutputsDetail
        ? { ...state.soundOutputsDetail }
        : {};

      if (
        updatePlaylitsInSoundOutputsDetail &&
        updatePlaylitsInSoundOutputsDetail[action.payload.soundOutputId]
      ) {
        updatePlaylitsInSoundOutputsDetail[action.payload.soundOutputId] = {
          ...state.soundOutputsDetail[action.payload.soundOutputId],
          playlists: state.soundOutputsDetail[
            action.payload.soundOutputId
          ].playlists.map((item) => {
            if (item.id === action.payload.playlist.id) {
              return {
                ...item,
                startDate: action.payload.playlist.startDate,
                endDate: action.payload.playlist.endDate,
                isSequentialPlayback:
                  action.payload.playlist.isSequentialPlayback,
              };
            }

            return item;
          }),
        };
      }

      return Object.assign({}, state, {
        soundOutputsDetail: updatePlaylitsInSoundOutputsDetail,
      });

    case constants.CLEAR_ERRORS:
      return Object.assign({}, state, clearErrors);

    case constants.DEVICE_DETAIL_TOGGLE:
      return Object.assign({}, state, {
        deviceDetailOpen: !state.deviceDetailOpen,
        soundOutputDetailOpen: state.deviceDetailOpen
          ? false
          : state.soundOutputDetailOpen,
      });

    case constants.SOUNDOUTPUT_DETAIL_TOGGLE:
      return Object.assign({}, state, {
        soundOutputDetailOpen: !state.soundOutputDetailOpen,
      });

    default:
      return state;
  }
}

export default reducer;
