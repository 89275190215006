import moment from "moment-timezone";
import "moment/locale/pt";
const defaultIso = "pt";
moment.locale("pt");

export const convertDateUTCToLocale = (date, clearTime) => {
  const oldLocale = moment.locale();

  if (date[date.length - 1] !== "Z") {
    if (oldLocale !== defaultIso) moment.locale(defaultIso);

    if (clearTime) {
      return new Date(
        moment(date).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).format("MM/DD/YYYY hh:mm:ss A") + " UTC"
      );
    }

    return new Date(moment(date).format("MM/DD/YYYY hh:mm:ss A") + " UTC");
  }

  if (oldLocale !== defaultIso) moment.locale(oldLocale);
  return new Date(date);
};

export const DateInUTC = (date) => {
  if (date[date.length - 1] === "Z") {
    date = date.replace("Z", "");
  }

  const newDate = new Date(date);
  return new Date(newDate.toISOString());
};

export const BooleanValueFormat = (value) => {
  return value ? "Sim" : "Não";
};

export const HourShortFormat = (value) => {
  if (!value) return "--:--";
  const rest = moment(value, ["HH:mm"]).format("HH:mm");
  return rest;
};

export const HourFormat = (value, timezone) => {
  if (!value) return "--:--";
  const rest = moment(value)
    .utcOffset(timezone || "")
    .format("HH:mm:ss");
  return rest;
};

export const DateShortFormat = (value, format) => {
  if (!value) return "-- / -- / ----";
  const rest = moment(value).format(format || "L");
  return rest;
};

export const DateLongFormat = (value, inUTC) => {
  if (!value || value.indexOf("0001-01-01") === 0) return "--- -- --";
  const rest = moment(inUTC ? DateInUTC(value) : convertDateUTCToLocale(value)).format("L LT");
  return rest;
};

export const DayOfWeekShortFormat = (value) => {
  if (!value) return "---";
  const rest = moment().day(value).format("ddd");
  return rest;
};

export const DayOfWeekFormat = (value) => {
  if (!value) return "---";
  const rest = moment().day(value).format("dddd");
  return rest.replace("-feira", "");
};

export const MonthShortFormat = (value) => {
  if (value === undefined || value === null) return "---";
  const rest = moment().month(value).format("MMM");
  return rest;
};

export const isPhoneNumber = (value) =>
  value && !/^(9[1236]\d) ?(\d{3}) ?(\d{3})$/gm.test(value) ? "Número de telemóvel inválido" : undefined;

export const formatBytes = (a, b = 2) => {
  if (0 === a) return "0 Bytes";
  const c = 0 > b ? 0 : b,
    d = Math.floor(Math.log(a) / Math.log(1024));
  return (
    parseFloat((a / Math.pow(1024, d)).toFixed(c)) + " " + ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d]
  );
};

export const Capitalize = (s) => (s && s[0].toUpperCase() + s.toLowerCase().slice(1)) || "";
