const AuthConstants = {
  REGISTER_REQUEST: "AUTH_REGISTER_REQUEST",
  REGISTER_SUCCESS: "AUTH_REGISTER_SUCCESS",
  REGISTER_FAILURE: "AUTH_REGISTER_FAILURE",

  LOGIN_REQUEST: "AUTH_LOGIN_REQUEST",
  LOGIN_SUCCESS: "AUTH_LOGIN_SUCCESS",
  LOGIN_FAILURE: "AUTH_LOGIN_FAILURE",

  FORGOTPASSWORD_REQUEST: "AUTH_FORGOTPASSWORD_REQUEST",
  FORGOTPASSWORD_SUCCESS: "AUTH_FORGOTPASSWORD_SUCCESS",
  FORGOTPASSWORD_FAILURE: "AUTH_FORGOTPASSWORD_FAILURE",

  ACTIVATEACCOUNT_REQUEST: "AUTH_ACTIVATEACCOUNT_REQUEST",
  ACTIVATEACCOUNT_SUCCESS: "AUTH_ACTIVATEACCOUNT_SUCCESS",
  ACTIVATEACCOUNT_FAILURE: "AUTH_ACTIVATEACCOUNT_FAILURE",

  RESENDACTIVATION_REQUEST: "AUTH_RESENDACTIVATION_REQUEST",
  RESENDACTIVATION_SUCCESS: "AUTH_RESENDACTIVATION_SUCCESS",
  RESENDACTIVATION_FAILURE: "AUTH_RESENDACTIVATION_FAILURE",

  LOGOUT_REQUEST: "AUTH_LOGOUT_REQUEST",

  CLEAR_ERRORS: "AUTH_CLEAR_ERRORS",

  CREATE_CLIENT_REQUEST: "AUTH_CREATE_CLIENT_REQUEST",
  CREATE_CLIENT_SUCCESS: "AUTH_CREATE_CLIENT_SUCCESS",
  CREATE_CLIENT_FAILURE: "AUTH_CREATE_CLIENT_FAILURE",
};

export default AuthConstants;
