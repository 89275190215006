import React, { useEffect, useState } from "react";
import { Toast, ToastHeader, ToastBody } from "reactstrap";
import { howLong } from "../../helpers/howLong";

const ToastAlert = (props) => {
  const [timer, setTimer] = useState(0);
  const [show, setShow] = useState(true);
  const [howLongText, setHowLong] = useState(howLong(props.date, true));

  const closeToast = () => {
    if (props.closeHandler) {
      props.closeHandler();
    }
    setShow(!show);
  };

  const updateDate = () => {
    setTimer(timer + 1);
    if (timer > 2) closeToast();
    setHowLong(howLong(props.date, true));
  };

  useEffect(() => {
    const intervalID = setInterval(updateDate, 5000);
    return () => clearInterval(intervalID);
  });

  return (
    <Toast key={"toast-" + props.toastKey} isOpen={show} fade={false} aria-live="assertive" aria-atomic="true">
      <ToastHeader icon={props.icon || ""} className="w-100 position-relative">
        <span className="ms-2">{props.header}</span>
        <span className="text-muted how-long">{howLongText}</span>
        <button type="button" className="btn-close " onClick={closeToast}></button>
      </ToastHeader>
      <ToastBody>{props.body}</ToastBody>
    </Toast>
  );
};

export default ToastAlert;
