import React, { Component } from "react";
import propTypes from "prop-types";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { Link, withRouter } from "react-router-dom";
import { isEmail, isRequired } from "../../FormComponents/Validators";
import authActions from "../../../redux/reducers/authentication/auth.actions";
import { RenderTextInput } from "../../FormComponents/ReduxFormRenders/InputsRenders";
import Alert from "../../FormComponents/Alert";

export class LoginForm extends Component {
  componentDidMount() {
    const { doClearErrors } = this.props;

    doClearErrors();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.loggedIn && this.props.loggedIn) {
      const { onSuccess } = this.props;

      if (onSuccess) {
        onSuccess();
      } else {
        this.props.history.push("/");
      }
    }
  }

  showResults(obj, dispatch, props) {
    const { doLogin } = props;

    doLogin(obj.ac_email, obj.ac_password);
  }

  render() {
    const { loadingLogin, handleSubmit, message, requestErrors } = this.props;

    return (
      <form onSubmit={handleSubmit(this.showResults)}>
        <Field
          label="E-mail"
          name="ac_email"
          type="email"
          placeholder="o seu e-mail"
          autoComplete="username"
          component={RenderTextInput}
          validate={[isRequired, isEmail]}
        />
        <Field
          placeholder="a sua password"
          label="Password"
          name="ac_password"
          type="password"
          autoComplete="current-password"
          component={RenderTextInput}
          validate={[isRequired]}
        />
        <div className="d-block text-end">
          <Link to={"/login/forgot-password/"} className="small text-decoration-none">
            Esqueceu-se da password?
          </Link>
        </div>
        <button type="submit" className="btn btn-primary w-100 mt-3" disabled={loadingLogin}>
          Entrar
        </button>
        {message && <Alert className="alert-primary" message={message} />}
        {requestErrors && (
          <Alert className="alert-danger" message={requestErrors.status ? requestErrors.message : requestErrors} />
        )}
      </form>
    );
  }
}

LoginForm.propTypes = {
  onSuccess: propTypes.func,
};

const FORM_NAME = "accountform";

LoginForm = reduxForm({
  form: FORM_NAME,
})(LoginForm);

const mapStateToProps = (state) => {
  const { auth } = state;
  const { loggedIn, loadingLogin, error, message } = auth;

  return {
    loggedIn,
    loadingLogin,
    requestErrors: error,
    message,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doClearErrors: () => dispatch(authActions.clearErrors()),
    doLogin: (user, password) => {
      dispatch(authActions.login(user, password));
    },
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginForm));
