import React from "react";

function PlaylistCover({ images }) {
  return (
    <>
      {images && (
        <div className="playlist-cover me-3">
          {[...Array(4)].map((arr, i) => (
            <div
              key={"cover-img-" + i}
              style={images[i] ? { backgroundImage: `url("${images[i]}")` } : {}}
            ></div>
          ))}
        </div>
      )}
    </>
  );
}

export default PlaylistCover;
