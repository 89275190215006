import Login from "../components/Login";
import AccountActivation from "../components/Login/AccountActivation";
import ForgotPassword from "../components/Login/ForgotPassword";
import ResetPassword from "../components/Login/ResetPassword";

const PublicRoutes = {
  login: {
    path: "/login",
    key: "login",
    title: "Login",
    exact: true,
    isProtected: false,
    component: Login,
  },
  about: {
    path: "/login/forgot-password",
    key: "forgot-password",
    title: "Password Esquecida",
    exact: true,
    isProtected: false,
    component: ForgotPassword,
  },
  account_activation: {
    path: "/login/account-activation",
    key: "account_activation",
    title: "Ativação de Conta",
    exact: true,
    isProtected: false,
    useLocation: true,
    component: AccountActivation,
  },
  reset_password: {
    path: "/login/reset-password",
    key: "reset_password",
    title: "Redefinir Password",
    exact: true,
    isProtected: false,
    useLocation: true,
    component: ResetPassword,
  },
};

export default PublicRoutes;
