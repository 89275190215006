import constants from "./alerts.constants";

const initialState = { alertMessages: [] };
const clearErrors = { alertMessages: [] };

export function reducer(state = initialState, action) {
  switch (action.type) {
    case constants.SUCCESS:
      return Object.assign({}, state, {
        alertMessages: [...state.alertMessages, { type: "success", date: new Date().toISOString(), messages: action.messages }],
      });
    case constants.ERROR:
      return Object.assign({}, state, {
        alertMessages: [...state.alertMessages, { type: "danger", date: new Date().toISOString(), messages: action.messages }],
      });
    case constants.WARNING:
      return Object.assign({}, state, {
        alertMessages: [...state.alertMessages, { type: "warning", date: new Date().toISOString(), messages: action.messages }],
      });
    case constants.CLEAR:
      return Object.assign({}, state, {
        alertMessages: state.alertMessages.filter((alert, index) => action.index !== index),
      });
    case constants.CLEAR_ALL:
      return Object.assign({}, state, clearErrors);

    default:
      return state;
  }
}

export default reducer;
