import { authHeader, putHeader, postHeader, getHeader } from "./helpers";
import { config } from "./config";

function handleResponse(response) {
  return new Promise((resolve, reject) => {
    const contentType = response.headers.get("content-type");

    if (response.ok) {
      // return json if it was returned in the response
      if (contentType && contentType.includes("application/json")) {
        try {
          response.json().then((json) => {
            resolve && resolve(json);
          });
        } catch (e) {
          reject(["Ocorreu um erro, por favor, tente novamente."]);
        }
      } else {
        reject(["Ocorreu um erro, por favor, tente novamente."]);
      }
    } else if (response.status === 503) {
      reject({
        status: 503,
        message: "O servidor está indisponível. Por favor tente mais tarde.",
      });
    } else if (response.status === 401) {
      reject({
        status: 401,
        message: "A sua sessão expirou ou você não tem permissão para realizar estas ações, faça login novamente.",
      });
    } else if (response.status === 403) {
      reject({
        status: 403,
        message: "Você não tem permissões para realizar esta ação.",
      });
    } else if (contentType && contentType.includes("application/json")) {
      response.json().then((json) =>
        reject({
          status: response.status,
          message: json.errors || json.message || ["Ocorreu um erro, por favor, tente novamente."],
        })
      );
    } else {
      response.text().then((text) => reject(text || ["Ocorreu um erro, por favor, tente novamente."]));
    }
  });
}

function handleError(error) {
  console.error("handle error", error);
  if (error && error.message === "Failed to fetch")
    return Promise.reject({ status: 408, message: "O servidor parece estar offline." });
  return Promise.reject(error && error.message);
}

function getAll(route, ...params) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  let queryParams = "";

  if (params && params.length > 0) {
    queryParams += "?";

    for (const param of params) {
      for (const key of Object.keys(param)) {
        if (param[key]) {
          queryParams += `${key}=${param[key]}&`;
        }
      }
    }
  }

  return fetch(config.apiUrl + route + queryParams, requestOptions).then(handleResponse, handleError);
}

function getById(id, route) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(config.apiUrl + route + id, requestOptions).then(handleResponse, handleError);
}

function register(obj, route) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(obj),
  };

  return fetch(config.apiUrl + route, requestOptions).then(handleResponse, handleError);
}

function update(obj, route) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
    body: JSON.stringify(obj),
  };

  return fetch(config.apiUrl + route, requestOptions).then(handleResponse, handleError);
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id, route) {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };

  return fetch(config.apiUrl + route + id, requestOptions).then(handleResponse, handleError);
}

export const baseService = {
  getAll,
  getById,
  register,
  update,
  delete: _delete,
  handleResponse,
  handleError,
  _delete,
  putHeader,
  postHeader,
  getHeader,
};

export default baseService;
