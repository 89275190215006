import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import LoginForm from "./components/LoginForm";

class Login extends Component {
  componentDidMount() {
    const { loggedIn } = this.props;

    if (loggedIn) {
      this.props.history.push("/");
    }
  }

  render() {
    return (
      <div className="container-fluid login-container bg-gradient-aurora h-100">
        <div className="row h-100 justify-content-center">
          <div className="col-12 align-self-center">
            <div className="card text-white bg-dark">
              <div className="card-body">
                <div className="brand"></div>
                <p className="mt-2 mb-0">Bem-vindo</p>
                <h3 className="card-title fw-light mb-4">Efetue o seu login</h3>
                <LoginForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Login);
