import * as signalR from "@microsoft/signalr";
import config from "../../services/config";
import { getToken } from "../../services/helpers";
import constants from "../reducers/signalR/signalR.constants";
import alertActions from "../reducers/alerts/alerts.actions";
import accountConstants from "../reducers/account/account.constants";

let hubConnection;

const initHubConnection = (store) => {
  hubConnection = new signalR.HubConnectionBuilder()
    .withUrl(`${config.apiUrl}osmhub?clientType=1`, {
      accessTokenFactory: () => getToken(),
    })
    .configureLogging(signalR.LogLevel.Error)
    .withAutomaticReconnect([0, 3000, 10000, 20000, 40000, 60000])
    .build();

  hubConnection.onclose((err) => {
    console.log("Connection closed :(");
    store.dispatch({ type: constants.HUB_DISCONNECTED });
  });

  hubConnection.on("handle_exception", (err) => {
    console.log("Connection closed :(", err);
    store.dispatch({ type: constants.HUB_DISCONNECTED });
  });
};

const startHub = (store) => {
  hubConnection
    .start()
    .then(() => {
      console.log("hub connected!");
      store.dispatch({ type: constants.HUB_CONNECTED });
    })
    .catch((err) => {
      console.log("Error while establishing connection :(");
      store.dispatch({ type: constants.HUB_DISCONNECTED });
      store.dispatch(
        alertActions.error(
          "Não foi possível estabelecer uma ligação real-time com o servidor."
        )
      );
    });
};

const startListening = (store) => {
  console.log("start listening", !!hubConnection);
  if (!hubConnection) initHubConnection(store);

  hubConnection.on("AllOnPremises", (data) => {
    // console.log("all customer devices =>", data);
    // store.dispatch({ type: licensesConstants.UPDATE_REMOTE_STATUS, devices: data });
    // store.dispatch({
    //   type: constants.UPDATE_UNKNOWN_DEVICES,
    //   devices: data.filter((d) => !d.isValid),
    // });
  });

  hubConnection.on("OnPremiseStatus", (data) => {
    // console.log("customer device status =>", data);
    store.dispatch({ type: accountConstants.UPDATE_REMOTE_STATUS, data });
  });

  hubConnection.on("OnPremisePlayersStatus", (data) => {
    // console.log("clients players status =>", data);
    store.dispatch({
      type: accountConstants.UPDATE_REMOTE_PLAYERS_STATUS,
      data,
    });
  });

  hubConnection.on("OnPremisePlayerActionFinished", (data) => {
    // console.log("clients players action callback =>", data);
    // store.dispatch({ type: licensesConstants.UPDATE_REMOTE_PLAYERS_STATUS, data });
  });

  // hubConnection.on("OnPremiseStreamStatus", (data) => {
  //   console.log("clients stream update =>", data);
  //   // store.dispatch({ type: licensesConstants.UPDATE_REMOTE_HARDWARE_STATUS, data });
  // });

  // hubConnection.on("DownloadStream", (stream) => {
  //   const data = _base64ToArrayBuffer(stream.data);
  //   if (this.mediaSource.readyState === "open") {
  //     console.log('append buffer')
  //     this.sourceBuffer.appendBuffer(data);
  //   }
  // });

  startHub(store);
};

function signalRMiddleware(store) {
  return (next) => (action) => {
    if (action.signalR) {
      switch (action.type) {
        case constants.INIT_HUB:
          startListening(store);
          break;

        case constants.START_REALTIME_HARDWARE_STATUS:
          if (hubConnection && hubConnection.state === "Connected") {
            hubConnection.invoke("AskOnPremiseStatus", action.deviceId);
          }
          break;

        case constants.STOP_REALTIME_HARDWARE_STATUS:
          if (hubConnection && hubConnection.state === "Connected") {
            hubConnection.invoke("StopAskOnPremiseStatus", action.deviceId);
          }
          break;

        case constants.SEND_PLAYER_ACTION:
          if (hubConnection && hubConnection.state === "Connected") {
            hubConnection.invoke(
              "CustomerSendPlayerAction",
              action.deviceId,
              action.soundOutputId,
              action.playerAction
            );
          }
          break;

        case constants.SEND_PRIORITY_PLAYLIST_TO_PLAY:
          if (hubConnection && hubConnection.state === "Connected") {
            console.log(
              "send playlist",
              action.deviceId,
              action.soundOutputId,
              action.playlistId,
              action.endDate
            );
            hubConnection.invoke(
              "CustomerSendPriorityPlaylistToPlay",
              action.deviceId,
              action.soundOutputId,
              action.playlistId,
              action.endDate
            );
          }
          break;

        case constants.STOP_PRIORITY_PLAYLIST:
          if (hubConnection && hubConnection.state === "Connected") {
            hubConnection.invoke(
              "CustomerStopPriorityPlaylist",
              action.deviceId,
              action.soundOutputId
            );
          }
          break;

        case constants.SET_PLAYER_VOLUME:
          if (hubConnection && hubConnection.state === "Connected") {
            hubConnection.invoke(
              "ChangePlayerVolume",
              action.deviceId,
              action.soundOutputId,
              action.volume
            );
          }
          break;

        default:
          break;
      }
    }

    return next(action);
  };
}

export default signalRMiddleware;
