import { DashboardView } from "../components/Dashboard";
import Roles from "./roles";

const AdminRoutes = {
  dashboard: {
    path: "/",
    key: "dashboard",
    title: "Controlo de Players",
    exact: true,
    isProtected: true,
    roles: [Roles.CUSTOMER_USER],
    component: DashboardView,
  },
};

export default AdminRoutes;
