import React from "react";

function MusicCover({ image }) {
  if (!image)
    return (
      <div style={{ width: "80px", height: "80px", color: "#fff" }} className="bg-gradient-green d-flex align-items-center justify-content-center">
        <i
          className="las la-music"
          style={{ fontSize: "40px", color: "#ff" }}
        ></i>
      </div>
    );

  return (
    <>
      <div
        key={"music-img"}
        style={{
          backgroundImage: `url("${image}")`,
          width: "80px",
          height: "80px",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      ></div>
    </>
  );
}

export default MusicCover;
