import React, { Component } from "react";
import PropTypes from "prop-types";
// import Sidebar from "./Sidebar";
import Topbar from "./Topbar";
import ToastContainer from "./GlobalComponents/ToastContainer";

class Layout extends Component {
  render() {
    const { children } = this.props;

    return (
      <>
        <ToastContainer />
        {/* <Sidebar /> */}
        <main className="layout">
          <Topbar />          
          {children}
        </main>
      </>
    );
  }
}

Layout.defaultProps = {};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
