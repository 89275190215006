import constants from "./signalR.constants";

const initialState = {
  loadingInitRealtime: false,
  realtimeConnected: false,
};

export function auth(state = initialState, action) {
  switch (action.type) {
    case constants.INIT_HUB:
      return Object.assign({}, state, { loadingInitRealtime: true });

    case constants.HUB_CONNECTED:
      return Object.assign({}, state, {
        loadingInitRealtime: false,
        realtimeConnected: true,
      });

    case constants.HUB_DISCONNECTED:
      return Object.assign({}, state, {
        loadingInitRealtime: false,
        realtimeConnected: false,
      });

    default:
      return state;
  }
}

export default auth;
