import React, { Component } from "react";
import { connect } from "react-redux";
import accountActions from "../../redux/reducers/account/account.actions";
import config from "../../services/config";
import Loading from "../GlobalComponents/Loading";
import DeviceDetail from "./components/DeviceDetail";
import DeviceStatus from "./components/DeviceStatus";

export class view extends Component {
  constructor(props) {
    super();

    this.state = {};

    this.deviceClick = this.deviceClick.bind(this);
  }

  componentDidMount() {
    const { doGetAllLicenses } = this.props;
    doGetAllLicenses();
  }

  licenseClick = (licenseId, e) => {
    if (e) e.preventDefault();

    if (
      e.target.classList["value"] &&
      e.target.classList["value"].indexOf("collapsed") === -1
    ) {
      const { doGetLicenseById } = this.props;
      this.setState({ currentLicenseId: licenseId });
      doGetLicenseById(licenseId);
    }
  };

  deviceClick = (deviceId, e) => {
    if (e) e.preventDefault();

    if (deviceId) {
      this.setState({ currentDeviceId: deviceId });
      this.props.toggleDeviceDetail();
    }
  };

  render() {
    const { currentDeviceId, currentLicenseId } = this.state;
    const { loadingGetAll, loadingGetById, items } = this.props;

    if (loadingGetAll || !items) return <Loading />;

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            {items.length === 0 && (
              <p className="text-center text-muted">
                <i className="las la-sad-cry " style={{ fontSize: "64px" }}></i>
                <span className="d-block">
                  Não contem nenhuma licença ativa na sua conta.
                  <br />
                  Contacte-nos para obter mais informações.
                </span>
              </p>
            )}
            <div className="list-group">
              {items.map((item, i) => (
                <div className="d-block license-row " key={"license-" + i}>
                  <a
                    href={"#collapse-devices-" + i}
                    className="list-group-item list-group-item-action p-2 d-flex align-items-center gap-3"
                    data-bs-toggle="collapse"
                    aria-current="true"
                    aria-expanded={item.licenseId === currentLicenseId}
                    aria-controls={"collapse-devices-" + i}
                    onClick={this.licenseClick.bind(this, item.licenseId)}
                  >
                    <div
                      style={{
                        width: "80px",
                        height: "24px",
                        background: `transparent url(${config.cdnUrl}/${item.brandLogoUrl}) no-repeat center center`,
                        backgroundSize: "contain",
                      }}
                    ></div>
                    <div className="d-flex w-100 justify-content-between">
                      <h5 className="mb-0">
                        {item.brandName + " - " + item.licenseName}
                      </h5>
                      <small>Activa</small>
                    </div>
                  </a>
                  <div
                    className={
                      "collapse multi-collapse " +
                      (item.licenseId === currentLicenseId ? "show" : "")
                    }
                    id={"collapse-devices-" + i}
                  >
                    <div
                      className="p-2 bg-gray border-start border-bottom border-end"
                      style={{ minHeight: "100px" }}
                    >
                      <small className="d-block mb-2 text-muted">
                        {item.devices?.length} máquina
                        {item.devices?.length > 1 && "s"}
                      </small>
                      {loadingGetById ? (
                        <div className="w-100" style={{ height: "80px" }}>
                          <Loading />
                        </div>
                      ) : (
                        <ul className="list-group">
                          {item.devices?.map((device, j) => (
                            <li
                              key={"device-" + j}
                              className="list-group-item w-50 w-lg-33 p-2 cursor-pointer"
                              aria-current="true"
                              onClick={this.deviceClick.bind(
                                this,
                                device.deviceId
                              )}
                            >
                              <div className="d-flex w-100 justify-content-between">
                                <i
                                  className="las la-hdd text-muted mb-3"
                                  style={{
                                    fontSize: "48px",
                                    lineHeight: "32px",
                                  }}
                                ></i>
                                <DeviceStatus
                                  key={"ds-" + device.deviceId}
                                  className="me-1"
                                  lastUpdate={device.lastUpdate}
                                />
                              </div>
                              <p className="mb-0 lh-1 fw-bold">
                                {"#MÁQUINA " + (j + 1)}
                              </p>
                              <small className="text-muted">
                                {device.serialNumber}
                              </small>
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <DeviceDetail currentDeviceId={currentDeviceId} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { account } = state;
  const {
    loadingGetAll,
    loadingGetById,
    loadingGetLicenseId,
    items,
    error,
    message,
  } = account;

  return {
    items,
    loadingGetAll,
    loadingGetById,
    loadingGetLicenseId,
    requestErrors: error,
    message,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doGetAllLicenses: () => {
      dispatch(accountActions.getAll());
    },
    doGetLicenseById: (id) => {
      dispatch(accountActions.getById(id));
    },
    toggleDeviceDetail: () => dispatch(accountActions.toggleDeviceDetail()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(view);
