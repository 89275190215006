import React, { useEffect, useState } from "react";
import config from "../../services/config";

export default function PlayButton({
  id,
  url,
  index,
  state,
  playingState,
  prefix = "",
  setState,
  isLoadingAudio,
}) {
  const [isLoading, setIsLoading] = useState(state?.loading || false);

  useEffect(() => {
    if (state?.loading !== isLoading) {
      setIsLoading(state?.loading);
    }
  }, [state?.loading]);

  const onClickPlayButton = () => {
    let newPlaying = {};
    newPlaying[prefix + "player-" + index] =
      !playingState[prefix + "player-" + index];

    setState({
      playing: newPlaying,
      audioUrl: url.indexOf("http") > -1 ? url : `${config.cdnUrl}${url}`,
    });
  };

  return (
    <>
      <button
        className={
          "btn btn-icon btn-sm ps-2 pe-2 pt-1 pb-1 " +
          (playingState[prefix + "player-" + index]
            ? "btn-primary"
            : "btn-dark")
        }
        onClick={onClickPlayButton}
        disabled={isLoading || isLoadingAudio}
      >
        {isLoading ? (
          <i className="las la-spinner"></i>
        ) : playingState[prefix + "player-" + index] ? (
          <i className="las la-pause"></i>
        ) : (
          <i className="las la-play"></i>
        )}
      </button>
    </>
  );
}
