import moment from "moment-timezone";
import "moment/locale/pt";
import { convertDateUTCToLocale } from "./valuesFormatters";
const defaultIso = "pt";
moment.locale("pt");

export const howLong = (date, precise, iso) => {
  const myLocaleDate = convertDateUTCToLocale(date);

  moment.locale(iso || defaultIso);
  const now = moment();
  const myDate = moment(myLocaleDate);
  const duration = moment.duration(now.diff(myDate));
  const todayInitialDay = moment().startOf("day");
  const myDateInitialDay = moment(myLocaleDate).startOf("day");

  if (precise) {
    if (duration.asSeconds() < 1) {
      return "agora";
    }
    if (duration.asSeconds() < 60) {
      return duration.asSeconds().toFixed(0) + " seg";
    } else if (duration.asMinutes() < 60) {
      return duration.asMinutes().toFixed(0) + " min";
    } else if (myDateInitialDay.isSame(todayInitialDay, "d")) {
      //today
      return myDate.format("HH:mm");
    } else if (duration.asHours() < 24) {
      //less 24h
      return myDate.format("HH:mm");
    }
  } else {
    if (myDateInitialDay.isSame(todayInitialDay, "d")) {
      return "hoje";
    }
  }

  if (myDateInitialDay.isSame(todayInitialDay.subtract(1, "days"), "d")) {
    //yesterday
    return "ontem";
  } else if (myDateInitialDay.isAfter(todayInitialDay.subtract(7, "days"))) {
    //a week
    return myDate.format("ddd");
  } else if (duration.asDays() < 365) {
    //less 365 days
    return myDate.format("L").substring(0, 5);
  } else {
    //years ago
    var years = now.diff(myDate, "years");
    if (years === 1) {
      return years + " ano";
    } else {
      return years + " anos";
    }
  }
};

export const howLongSeconds = (date, timezone) => {
  const myLocaleDate = timezone ? moment(date).utcOffset(timezone) : convertDateUTCToLocale(date);

  moment.locale(defaultIso);
  const now = moment();
  const myDate = moment(myLocaleDate);
  const duration = moment.duration(now.diff(myDate));

  return duration.asSeconds().toFixed(0);
};

export const whenHappen = (date, iso) => {
  moment.locale(iso || "pt");
  const myDate = moment(date);
  return myDate.format("lll");
};

export const howManyMinutes = (totalSeconds, short = true) => {
  const millis = totalSeconds * 1000;
  const minutes = Math.floor(millis / 60000);
  const seconds = ((millis % 60000) / 1000).toFixed(0);

  return ("0" + minutes).slice(-2) + (short ? ":" : " min ") + ("0" + seconds).slice(-2) + (short ? "" : " seg");
};

export const howManyTime = (totalSeconds, short = true) => {
  const millis = totalSeconds * 1000;
  const seconds = Math.floor((millis / 1000) % 60);
  const minutes = Math.floor((millis / (1000 * 60)) % 60);
  const hours = Math.floor((millis / (1000 * 60 * 60)) % 24);
  const days = Math.floor((millis / (1000 * 60 * 60 * 24)));

  let result = "";
  result += days ? ("0" + days).slice(-2) + (short ? ":" : " d ") : "";
  result += ("0" + hours).slice(-2) + (short ? ":" : " hrs ");
  result += ("0" + minutes).slice(-2) + (short ? ":" : " min ");

  return result + ("0" + seconds).slice(-2) + (short ? "" : " seg");
};

export const monthsBetweenRange = (startDate, endDate) => {
  startDate = moment(startDate);
  endDate = endDate ? moment(endDate) : moment(startDate).add(1, "year");

  let result = [];

  if (endDate.isBefore(startDate)) {
    return result;
  }

  while (startDate.isBefore(endDate)) {
    if (result.indexOf(startDate.month()) === -1) {
      result.push(startDate.month());
    }
    startDate.add(1, "month");
  }

  if (result.indexOf(endDate.month()) === -1) {
    result.push(endDate.month());
  }

  return result;
};

export const getIntervalOfHours = (startHour, endHour, timeInterval) => {
  if (!startHour || !endHour || startHour > endHour) return [];

  let currentHour = moment(startHour);
  let finish = false;
  let result = [moment(currentHour).format("HH:mm:ss")];

  while (!finish) {
    currentHour = moment(currentHour).add(timeInterval, "minutes");
    result.push(moment(currentHour).format("HH:mm:ss"));
    if (currentHour >= endHour) finish = true;
  }

  return result;
};

export const getDiferenceTimeZoneHours = (startDate, timeZoneStartDate, endDate, timeZoneEndDate) => {
  const startDateM = moment(startDate).utcOffset(timeZoneStartDate || "");
  const endDateM = moment(endDate).utcOffset(timeZoneEndDate || "");

  return endDateM.hour() - startDateM.hour();
};
