import React from "react";

export const Alert = ({ className, message }) => {
  return (
    <div key={'alert-' + Math.random()} className={"alert fade show " + (className || "")} role="alert">
      {message && Array.isArray(message) ? message[0] : message}
    </div>
  );
};

export default Alert;
