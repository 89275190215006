import constants from "./alerts.constants";

function success(messages) {
  return { type: constants.SUCCESS, messages };
}

function warning(messages) {
  return { type: constants.WARNING, messages };
}

function error(messages) {
  return { type: constants.ERROR, messages };
}

function clear(index) {
  return { type: constants.CLEAR, index };
}

const alertActions = {
  success,
  warning,
  error,
  clear,
};

export default alertActions;
