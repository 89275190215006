import React from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router"; // react-router v4/v5
import { ConnectedRouter } from "connected-react-router";
import { history } from "../redux/configStore";
import Layout from "../components/Layout";
import Roles from "./roles";
import NotFound from "./NotFound";
import PublicRoutes from "./routes";
import AdminRoutes from "./admin.routes";
import ActionMenu from "../components/ActionMenu";

const PrivateRoute = ({ component: TheComponent, loggedIn, roleAllowed, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return roleAllowed && loggedIn ? (
          <>
            <ActionMenu {...props} />
            <div className="d-table m-auto"><TheComponent {...props} /></div>
          </>
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              search: rest.path && rest.path !== "/" ? "?return=" + rest.path : "",
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
};

function AppRouter({ loggedIn, roleAllowed }) {
  const publicRoutes = Object.values(PublicRoutes)
    .filter((r) => !r.isProtected)
    .map((route, i) => (
      <Route key={route.key || "route-key" + i} path={route.path} exact={route.exact} component={route.component} />
    ));

  const privateRoutes = Object.values(AdminRoutes)
    .filter((r) => r.isProtected)
    .map((route, i) => (
      <PrivateRoute
        key={route.key || "private-route-key" + i}
        path={route.path}
        exact={route.exact}
        component={route.component}
        roleAllowed={roleAllowed}
        loggedIn={loggedIn}
      />
    ));

  return (
    <ConnectedRouter history={history}>
      <Switch>
        {publicRoutes}
        <Layout>{privateRoutes}</Layout>
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </ConnectedRouter>
  );
}

function mapStateToProps(state) {
  const { auth } = state;

  return {
    loggedIn: auth && auth.loggedIn,
    roleAllowed: auth && auth.user && auth.roles && auth.roles.some((x) => x === Roles.CUSTOMER_USER),
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AppRouter);
