import React, { Component } from "react";
import PropTypes from "prop-types";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";

const debounce = (func, wait, immediate) => {
  let timeout;

  return (...args) => {
    const later = () => {
      timeout = null;
      if (!immediate) func.apply(this, args);
    };

    const callNow = immediate && !timeout;

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);

    if (callNow) func.apply(this, args);
  };
};

const MySearch = (props) => {
  let input;

  const searchHandler = debounce((query) => {
    props.onSearch(query);
  }, 100);

  return (
    <div
      className={
        "input-group mb-3 w-100 w-md-50 w-xl-30 me-auto " +
        (props.className || "")
      }
    >
      <input
        type="text"
        className="form-control"
        ref={(n) => (input = n)}
        placeholder={props.placeholder}
        onChange={() => searchHandler(input.value)}
      />
      <span className="input-group-text">
        <i className="las la-search icon-flipped"></i>
      </span>
    </div>
  );
};

const MyToggleList = ({
  columns,
  onColumnToggle,
  toggles,
  toggleColumnHandler,
}) => (
  <div className="dropdown me-0">
    <button
      className="btn btn-icon dropdown-toggle "
      type="button"
      id="dropdownMenuColumns"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      <i className="las la-sliders-h"></i>
    </button>
    <div
      className="dropdown-menu dropdown-menu-end"
      aria-labelledby="dropdownMenuColumns"
    >
      {columns
        .filter(
          (column) =>
            !column.headerClasses ||
            column.headerClasses.indexOf("actions") === -1
        )
        .map((column) => ({ ...column, toggle: toggles[column.dataField] }))
        .map((column, i) => (
          <button
            key={"drop-column-" + i}
            type={"button"}
            role="menuitem"
            className="dropdown-item"
            onClick={(e) => {
              e.stopPropagation();
              onColumnToggle(column.dataField);
              if (toggleColumnHandler) toggleColumnHandler(i);
            }}
          >
            <div className="form-check pointer-events-none">
              <input
                type="checkbox"
                id={"column-filter-" + i}
                className="form-check-input"
                checked={column.toggle}
                readOnly={true}
              />
              <label
                className="form-check-label"
                htmlFor={"column-filter-" + i}
              >
                {column.text}
              </label>
            </div>
          </button>
        ))}
    </div>
  </div>
);

export class TableComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columnsHidden: props.columns.map((c) => c.hidden || false),
    };

    this.toggleColumn = this.toggleColumn.bind(this);
  }

  componentDidMount() {
    const { tableKey } = this.props;

    this.pagination = paginationFactory(
      this.props.paginationProps || { sizePerPage: 10 }
    );

    if (tableKey) {
      const storageColumnsHidden = localStorage.getItem(tableKey);

      if (!storageColumnsHidden) {
        localStorage.setItem(tableKey, this.state.columnsHidden);
      } else {
        var newColumnsHidden = storageColumnsHidden
          .split(",")
          .map((x) => x === "true");
        this.setState({ columnsHidden: newColumnsHidden });
      }
    }
  }

  toggleColumn(index) {
    const { tableKey } = this.props;

    if (tableKey) {
      let newColumnsHidden = this.state.columnsHidden;
      newColumnsHidden[index] = !newColumnsHidden[index];
      localStorage.setItem(tableKey, newColumnsHidden);
      this.setState({ columnsHidden: newColumnsHidden });
    }
  }

  render() {
    const { columnsHidden } = this.state;
    const {
      data,
      columns,
      selectRow,
      className,
      tableClassName,
      searchClassName,
      searchPlaceholder,
      defaultSorted,
      rowClasses,
      rowEvents,
      keyField,
      expandRow,
      paginationProps,
      noDataIndication,
      disabled,
      headerElements,
      remote,
      onTableChange,
      onSearch,
      showSearch = true,
      showColumnsToggle = true,
    } = this.props;

    this.pagination = paginationProps
      ? paginationFactory(paginationProps || { sizePerPage: 10 })
      : this.pagination;

    const tableColumns = columns.map((column, i) => {
      column.attrs = { "data-title": column.text };
      column.hidden = columnsHidden[i];
      return column;
    });

    return (
      <ToolkitProvider
        {...this.props}
        keyField={keyField}
        data={data || []}
        columns={tableColumns}
        expandRow={expandRow}
        columnToggle
        search
      >
        {(props) => {
          return (
            <>
              <div className="d-flex">
                {showSearch && (
                  <MySearch
                    {...props.searchProps}
                    onSearch={onSearch ? onSearch : props.searchProps.onSearch}
                    placeholder={searchPlaceholder || "Procurar"}
                    className={searchClassName || ""}
                  />
                )}
                {headerElements && <>{headerElements}</>}
                {showColumnsToggle && (
                  <MyToggleList
                    {...props.columnToggleProps}
                    toggleColumnHandler={this.toggleColumn}
                  />
                )}
              </div>
              <BootstrapTable
                wrapperClasses={
                  " " + (className ? className : "")
                }
                classes={
                  "table-borderless " +
                  (disabled ? " disabled" : "") +
                  " " +
                  (tableClassName || "table-light")
                }
                striped
                hover
                bordered={false}
                pagination={this.pagination}
                noDataIndication={noDataIndication}
                rowEvents={rowEvents}
                rowClasses={rowClasses || ""}
                selectRow={selectRow}
                defaultSorted={defaultSorted}
                remote={remote || false}
                onTableChange={onTableChange || undefined}
                {...props.baseProps}
              />
            </>
          );
        }}
      </ToolkitProvider>
    );
  }
}

TableComponent.propTypes = {
  classes: PropTypes.string,
  columns: PropTypes.array,
  data: PropTypes.array,
  keyField: PropTypes.string,
  rowEvents: PropTypes.object,
};

export default TableComponent;
