import React, { Component } from "react";
import propTypes from "prop-types";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { Link, withRouter } from "react-router-dom";
import { isEmail, isRequired } from "../../FormComponents/Validators";
import authActions from "../../../redux/reducers/authentication/auth.actions";
import { RenderTextInput } from "../../FormComponents/ReduxFormRenders/InputsRenders";
import Alert from "../../FormComponents/Alert";

export class ForgotPasswordForm extends Component {
  componentDidMount() {
    const { doClearErrors } = this.props;

    doClearErrors();
  }

  componentDidUpdate(prevProps) {
    const { onSuccess } = this.props;

    if (
      !prevProps.loadingForgotPassword &&
      this.props.loadingForgotPassword &&
      !this.props.requestErrors &&
      onSuccess
    ) {
      onSuccess();
    }
  }

  showResults(obj, dispatch, props) {
    const { forgotPassword, doClearErrors } = props;

    doClearErrors();
    forgotPassword(obj.ac_email);
  }

  render() {
    const { loadingForgotPassword, successForgotPassword, handleSubmit, message, requestErrors } = this.props;

    return (
      <form onSubmit={handleSubmit(this.showResults)}>
        <div className={successForgotPassword ? "d-none" : ""}>
          <Field
            label="E-mail"
            name="ac_email"
            type="email"
            placeholder="o seu e-mail"
            component={RenderTextInput}
            validate={[isRequired, isEmail]}
          />
          <button type="submit" className="btn btn-primary w-100" disabled={loadingForgotPassword}>
            Recuperar Password
          </button>
        </div>
        {message && <Alert className="alert-primary" message={message} />}
        {requestErrors && (
          <Alert className="alert-danger" message={requestErrors.status ? requestErrors.message : requestErrors} />
        )}
        <p className="d-block small text-center mt-3 mb-0">
          Voltar ao ecrã de{" "}
          <Link to={"/login"} className="text-decoration-none">
            Login
          </Link>
          .
        </p>
      </form>
    );
  }
}

ForgotPasswordForm.propTypes = {
  onSuccess: propTypes.func,
};

const FORM_NAME = "accountform";

ForgotPasswordForm = reduxForm({
  form: FORM_NAME,
})(ForgotPasswordForm);

const mapStateToProps = (state) => {
  const { auth } = state;
  const { loadingForgotPassword, successForgotPassword, error, message } = auth;

  return {
    loadingForgotPassword,
    successForgotPassword,
    requestErrors: error,
    message,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doClearErrors: () => dispatch(authActions.clearErrors()),
    forgotPassword: (user) => {
      dispatch(authActions.forgotPassword(user));
    },
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordForm));
