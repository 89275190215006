require("dotenv").config();

const { REACT_APP_API_URL } = process.env;

export const config = {
  apiUrl: REACT_APP_API_URL,
  cdnUrl: `https://d27frxbbqk67ky.cloudfront.net`
};

export default config;
