import constants from "./auth.constants";
import { authService as theService } from "../../../services/general/auth.service";
import Roles from "../../../routes/roles";

function checkSavedLogin() {
  function success(user, roles) {
    return { type: constants.LOGIN_SUCCESS, user, roles };
  }
  function failure() {
    return { type: constants.LOGIN_FAILURE };
  }

  const profile = theService.getProfile();

  if (profile && profile.email) {
    const roles = theService.getRoles();

    return (dispatch) => {
      dispatch(success(profile, roles));
    };
  } else {
    return (dispatch) => {
      dispatch(failure());
    };
  }
}

function login(username, password) {
  function request(user) {
    return { type: constants.LOGIN_REQUEST, user };
  }
  function success(user, roles) {
    return { type: constants.LOGIN_SUCCESS, user, roles };
  }
  function failure(error) {
    return { type: constants.LOGIN_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request({ username }));
    theService.login(username, password).then(
      (resp) => {
        if (!resp.roles.includes(Roles.CUSTOMER_USER)) {
          theService.logout();
          dispatch(failure("Este utilizador não tem permissão para utilizar este sistema."));
        } else {
          dispatch(success(resp.profile, resp.roles));
        }
      },
      (error) => {
        localStorage.removeItem("id_token");
        dispatch(failure(error));
      }
    );
  };
}

function forgotPassword(email) {
  function request(email) {
    return { type: constants.FORGOTPASSWORD_REQUEST, email };
  }
  function success(message) {
    return { type: constants.FORGOTPASSWORD_SUCCESS, message };
  }
  function failure(error) {
    return { type: constants.FORGOTPASSWORD_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request(email));
    theService.forgotPassword(email).then(
      (resp) => {
        dispatch(success(resp.data.message + " (" + email + ")"));
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

function clearErrors() {
  function request() {
    return { type: constants.CLEAR_ERRORS };
  }
  return (dispatch) => {
    dispatch(request());
  };
}

function activateAccount(email, password, confirmPassword, token) {
  function request(email, token) {
    return { type: constants.ACTIVATEACCOUNT_REQUEST, email, token };
  }
  function success(user, roles) {
    return { type: constants.ACTIVATEACCOUNT_SUCCESS, user, roles };
  }
  function failure(error) {
    return { type: constants.ACTIVATEACCOUNT_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request(email, token));
    theService.activateAccount({ email, password, confirmPassword, token }).then(
      (resp) => {
        if (resp.roles.includes(Roles.CUSTOMER_USER)) {
          dispatch(success(resp.profile, resp.roles));
        } else {
          theService.logout(true);
          dispatch(failure("Esta conta não pode ser utilizada."));
        }
      },
      (error) => {
        dispatch(failure(error));
      }
    );
  };
}

function resendActivationLink(email) {
  function request(email) {
    return { type: constants.RESENDACTIVATION_REQUEST, email };
  }

  function success(message) {
    return { type: constants.RESENDACTIVATION_SUCCESS, message };
  }

  function failure(error) {
    return { type: constants.RESENDACTIVATION_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request(email));
    theService.resendActivationLink(email).then(
      (resp) => {
        dispatch(success(resp.data.message + " (" + email + ")"));
      },
      (error) => {
        if (error.status) {
          dispatch(failure(error.message, error.status));
        } else {
          dispatch(failure(error));
        }
      }
    );
  };
}

function logout() {
  function request() {
    return { type: constants.LOGOUT_REQUEST };
  }

  return (dispatch) => {
    theService.logout();
    dispatch(request());
  };
}

function isLoggedIn() {
  const profile = theService.getProfile();
  const roles = theService.getRoles();

  return !!(profile && profile.email && roles && roles.includes(Roles.CUSTOMER_USER));
}

const authActions = {
  login,
  isLoggedIn,
  checkSavedLogin,
  forgotPassword,
  clearErrors,
  activateAccount,
  resendActivationLink,
  logout,
};

export {
  login,
  isLoggedIn,
  checkSavedLogin,
  forgotPassword,
  clearErrors,
  activateAccount,
  resendActivationLink,
  logout,
};

export default authActions;
