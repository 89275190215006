import { createBrowserHistory } from "history";
import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import { connectRouter, routerMiddleware } from "connected-react-router";
import thunk from "redux-thunk";
import AppActions from "./reducers/app/app.actions";
import signalRMiddleware from "./middlewares/signalRMiddleware";
import * as rootReducers from "./reducers";
import AuthConstants from "./reducers/authentication/auth.constants";

const { NODE_ENV } = process.env;
const IS_DEV = NODE_ENV === "development" && window.__REDUX_DEVTOOLS_EXTENSION__;

const initialState = {};
const history = createBrowserHistory();
const middlewares = [thunk, routerMiddleware(history), signalRMiddleware];

const rootReducer = combineReducers({
  ...rootReducers,
  router: connectRouter(history),
});

const appReducer = (state, action) => {
  if (action.type === AuthConstants.LOGOUT_REQUEST) {
    state = undefined;
  }

  return rootReducer(state, action);
};

const store = IS_DEV
  ? createStore(
      appReducer,
      initialState,
      compose(applyMiddleware(...middlewares), window.__REDUX_DEVTOOLS_EXTENSION__())
    )
  : createStore(appReducer, compose(applyMiddleware(...middlewares)));

store.dispatch(AppActions.initializeApp());

export { store, history };
