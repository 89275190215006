const SignalRConstants = {
  INIT_HUB: "INIT_HUB",
  HUB_CONNECTED: "HUB_CONNECTED",
  HUB_DISCONNECTED: "HUB_DISCONNECTED",
  START_REALTIME_HARDWARE_STATUS: "START_REALTIME_HARDWARE_STATUS",
  STOP_REALTIME_HARDWARE_STATUS: "STOP_REALTIME_HARDWARE_STATUS",
  SEND_PLAYER_ACTION: "REALTIME_SEND_PLAYER_ACTION",
  SEND_PRIORITY_PLAYLIST_TO_PLAY: "REALTIME_SEND_PRIORITY_PLAYLIST_TO_PLAY",
  STOP_PRIORITY_PLAYLIST: "REALTIME_STOP_PRIORITY_PLAYLIST",
  SET_PLAYER_VOLUME: "SET_PLAYER_VOLUME",
};

export default SignalRConstants;
